import React from 'react';
import icLock from '@/resources/icons/MainMenuLock.svg';
import icUnLock from '@/resources/icons/MainMenuUnLock.svg';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { EditorToolbarSelectors } from '../../../../../selectors/editorToolbar.selectors';
import { ToolbarBtn } from '../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import { EditorMode } from '../../../../../models/editorMode';
import { spreadsheetEditorModeChanged } from '../../../../../actions/entities/spreadsheet.actions';

export const SpreadsheepEditModeButton = () => {
    const dispatch = useDispatch();
    const isReadMode: boolean = useSelector(TabsSelectors.checkIsReadModeInActiveTab);
    const disabled: boolean = useSelector(EditorToolbarSelectors.getEdiitorModeDisabled());
    const tooltip: string = useSelector(EditorToolbarSelectors.getEdiitorModeTooltip);

    const handleModeChange = () => {
        dispatch(spreadsheetEditorModeChanged(!isReadMode ? EditorMode.Read : EditorMode.Edit));
    };

    return (
        <ToolbarBtn
            isActive={!isReadMode}
            icon={isReadMode ? icLock : icUnLock}
            size="small"
            dataTest={'spreadsheet-mode_button'}
            disabled={disabled}
            onClick={handleModeChange}
            tooltip={tooltip}
        />
    );
};
