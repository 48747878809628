import React, { useContext, useState } from 'react';
import { toggleCustomInlineStyle, getSelectionCustomInlineStyle } from 'draftjs-utils';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import ControlsContext from '../Controls.context';
import { DEFAULT_FONT_COLOR } from './Controls.constants';
import icTextColor from '@/resources/icons/MainMenuText-Color.svg';
import { useSharedState } from '../UseSharedState.hook';
import { ColorResult, SketchPicker } from 'react-color';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';

const getFocusedBlock = (editorState) => {
    const fontColor = getSelectionCustomInlineStyle(editorState, ['COLOR'])?.COLOR;

    if (fontColor) {
        return fontColor.replace('color-', '');
    }

    return DEFAULT_FONT_COLOR;
};

const getChangedBlock = (editorState, value) => toggleCustomInlineStyle(editorState, 'color', value);

const FontColorComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState: setCurrentStateForFont } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const [currentFontColor, setCurrentFontColor] = useState<string>(DEFAULT_FONT_COLOR);
    const selectFontColorHandler = (colorObject: ColorResult) => {
        const { hex } = colorObject;
        setCurrentFontColor(hex);
        setCurrentStateForFont(hex);
    };

    return (
        <ToolbarBtn
            icon={icTextColor}
            dataTest="wiki-toolbar-group_font-color-button"
            tooltip={intl.formatMessage(messages.fontColor)}
            overlay={<SketchPicker color={currentFontColor} onChange={selectFontColorHandler} disableAlpha />}
            size="small"
        />
    );
};

export default FontColorComponent;
