import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Icon } from '../../Icon/Icon.component';
import theme from './ToolbarDotsBtn.scss';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { DividerComponent } from '../../TipTapTextEditor/Toolbar/controls/Divider.component';
import { TToolbarDotsBtn } from './toolbarBtns.types';

export const ToolbarDotsBtn = (props: TToolbarDotsBtn) => {
    const { icon, disabled, tooltip, hiddenGroups = [], containerWidth, onClick, rightOffset = 0 } = props;
    const [isActive, setIsActive] = useState<boolean>(false);
    const handleClickHeader = () => {
        onClick && onClick();
        setIsActive((prevState) => !prevState);
    };
    const selectButtonRef: React.MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const portalRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
    const [topY, setTopY] = useState<number>(0);
    const [drobdownWidth, setDrobdownWidth] = useState<number>(0);
    const [drobDownNewRows, setDrobDownNewRows] = useState<boolean[]>([]);

    const recalculateDropdownOptions = () => {
        const drobdownChildren = portalRef.current?.children;
        const childrenRect = Array.from(drobdownChildren || []).map((child) => child.getBoundingClientRect().width);
        const paddingRight: number = portalRef.current
            ? +getComputedStyle(portalRef.current).paddingRight.slice(0, -2)
            : 8;
        const menuWidth = (containerWidth || 500) - rightOffset;
        const drobdownWidth: number = (() => {
            let width: number = 0;
            for (let i = 0; i < childrenRect.length; i++) {
                width = width + childrenRect[i];
                if (width > menuWidth - paddingRight) {
                    width = width - childrenRect[i];
                    break;
                }
            }
            return width;
        })();
        setDrobdownWidth(drobdownWidth);
    };

    useEffect(() => {
        const clientRects: DOMRect | undefined = selectButtonRef.current?.getBoundingClientRect();
        setTopY(window.innerHeight - (clientRects?.bottom || 0) - 5);
    }, [selectButtonRef.current, isActive]);

    useEffect(() => {
        setDrobDownNewRows(calcNewRow());
    }, [containerWidth]);

    useLayoutEffect(() => {
        recalculateDropdownOptions();
    });

    const calcNewRow = (): boolean[] => {
        const drobdownChildren: HTMLCollection | undefined = portalRef.current?.children;
        const childrenRect: DOMRect[] = Array.from(drobdownChildren || []).map((child) =>
            child.getBoundingClientRect(),
        );
        const result: boolean[] = childrenRect.map((childRect, ind) => {
            if (childRect.top !== childrenRect[ind - 1]?.top) return true;
            return false;
        });

        return result;
    };

    return (
        <div className={theme.selectorContainer}>
            <Tooltip title={tooltip}>
                <div
                    className={classNames(theme.selectHeader, disabled ? theme.disabled : '', isActive && theme.active)}
                >
                    <span>
                        <button
                            ref={selectButtonRef}
                            type="button"
                            onClick={handleClickHeader}
                            className={classNames(theme.headerBtn, isActive && theme.active)}
                            disabled={disabled}
                        >
                            <Icon spriteSymbol={icon} disabled={disabled} />
                        </button>
                    </span>
                </div>
            </Tooltip>
            {isActive &&
                createPortal(
                    <div
                        ref={portalRef}
                        data-test={`${props['data-test']}_dropdown`}
                        className={theme.dropdownContainer}
                        style={{
                            top: `-${topY}px`,
                            maxWidth: drobdownWidth ? `${drobdownWidth + 16}px` : 'auto',
                            marginRight: rightOffset,
                        }}
                    >
                        {hiddenGroups?.map((group, ind) => {
                            return (
                                <div
                                    className={classNames(
                                        theme.hideGroupContainer,
                                        drobDownNewRows[ind] && theme.newRow,
                                    )}
                                >
                                    <div className={theme.dividerContainer}>
                                        <DividerComponent />
                                    </div>
                                    {group}
                                    <div className={theme.dividerContainer}>
                                        <DividerComponent />
                                    </div>
                                </div>
                            );
                        })}
                    </div>,
                    document.body,
                )}
        </div>
    );
};
