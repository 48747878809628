import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import arrowLeft from '../../../../../../resources/icons/arrow-left.svg';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import { workspaceTabSetParams } from '../../../../../../actions/tabs.actions';
import { TabsSelectors } from '../../../../../../selectors/tabs.selectors';
import { NodeId } from '../../../../../../serverapi/api';

export const FilterInputBtn = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getFilterInputBtnDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getFilterInputBtnTooltip);
    const activeTabNodeId: NodeId | undefined = useSelector(TabsSelectors.getActiveTabId);
    const nodeFilterInput: boolean = useSelector(TabsSelectors.getActiveTabNodeFilterInput);

    const handleNodeFilterInputChange = () => {
        if (activeTabNodeId) dispatch(workspaceTabSetParams(activeTabNodeId, { nodeFilterInput: !nodeFilterInput }));
    };

    return (
        <ToolbarBtn
            icon={arrowLeft}
            size="small"
            dataTest="main-panel_checkbox-inbox"
            disabled={disabled}
            onClick={handleNodeFilterInputChange}
            tooltip={tooltip}
            isActive={nodeFilterInput && !disabled}
        />
    );
};
