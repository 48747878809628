import React from 'react';
import icLock from '@/resources/icons/MainMenuLock.svg';
import icUnLock from '@/resources/icons/MainMenuUnLock.svg';
import { scriptChangeMode } from '../../../../../actions/entities/script.actions';
import { ToolbarBtn } from '../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { NodeId } from '../../../../../serverapi/api';
import { ScriptSelectors } from '../../../../../selectors/script.selectors';
import { EditorMode } from '../../../../../models/editorMode';

export const ScriptEditModeButton = () => {
    const dispatch = useDispatch();
    const activeTabId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const isReadMode: boolean = useSelector(TabsSelectors.checkIsReadModeInActiveTab);
    const tooltip: string = useSelector(ScriptSelectors.getEditModeButtonTooltip);

    const handleModeChange = () => {
        dispatch(scriptChangeMode(activeTabId, !isReadMode ? EditorMode.Read : EditorMode.Edit));
    };

    return (
        <ToolbarBtn
            isActive={!isReadMode}
            icon={isReadMode ? icLock : icUnLock}
            size="small"
            dataTest={isReadMode ? 'script_lock_toolbar_button_read' : 'script_lock_toolbar_button_edit'}
            onClick={handleModeChange}
            tooltip={tooltip}
        />
    );
};
