import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../CommonToolbar.messages';
import { TablePicker, TTablePickerSize } from 'UIKit';
import icTable from 'icons/toolbar/controls/ic-table.svg';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';

const getChangedBlock = (editor: Editor, { rows, cols }: TTablePickerSize) =>
    editor.chain().focus().insertTable({ rows, cols }).run();

export const TableComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, TTablePickerSize>({ stateObserver, getChangedBlock });
    const onChangeHandler = (tableSize: TTablePickerSize) => {
        setCurrentState(tableSize);
    };

    return (
        <ToolbarBtn
            icon={icTable}
            tooltip={intl.formatMessage(messages.insertTable)}
            overlay={<TablePicker onChange={onChangeHandler} />}
            size="small"
            dataTest="wiki-toolbar-group_add-table-button"
        />
    );
};
