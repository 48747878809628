import { Orientation, PaperFormat } from '../../../../utils/types';
import icGridPoint from '@/resources/icons/ic-grid-point.svg';
import icNoGrid from '@/resources/icons/ic-no-grid.svg';
import icGridA3Landscape from '@/resources/icons/ic-grid-a3-landscape.svg';
import icGridA4Landscape from '@/resources/icons/ic-grid-a4-landscape.svg';
import icGridA3Portrait from '@/resources/icons/ic-grid-a3-portrait.svg';
import icGridA4Portrait from '@/resources/icons/ic-grid-a4-portrait.svg';

export const GridTypeOnCanvas = {
    noGrid: undefined,
    dot: 'dot',
    'A4/PORTRAIT': `${PaperFormat.A4}/${Orientation.PORTRAIT}`,
    'A4/LANDSCAPE': `${PaperFormat.A4}/${Orientation.LANDSCAPE}`,
    'A3/PORTRAIT': `${PaperFormat.A3}/${Orientation.PORTRAIT}`,
    'A3/LANDSCAPE': `${PaperFormat.A3}/${Orientation.LANDSCAPE}`,
};

export const GridTypeIcons = {
    noGrid: icNoGrid,
    dot: icGridPoint,
    'A4/PORTRAIT': icGridA4Portrait,
    'A4/LANDSCAPE': icGridA4Landscape,
    'A3/PORTRAIT': icGridA3Portrait,
    'A3/LANDSCAPE': icGridA3Landscape,
};
