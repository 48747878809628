import icItalic from 'icons/toolbar/controls/ic-font-italic.svg';
import React, { useContext } from 'react';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import type { Editor } from '@tiptap/react';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { StateObserver } from '../../common/sharedState.class';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';

type TInlineStyleValue = {
    [key: string]: boolean;
};

const getFocusedBlock = (editor: Editor) => {
    return {
        BOLD: editor.isActive('bold'),
        ITALIC: editor.isActive('italic'),
        UNDERLINE: editor.isActive('underline'),
        STRIKETHROUGH: editor.isActive('strike'),
        SUPERSCRIPT: editor.isActive('superscript'),
        SUBSCRIPT: editor.isActive('subscript'),
        MONOSPACE: editor.isActive('code'),
    };
};

const getChangedBlock = (editor: Editor, action: string) => {
    switch (action) {
        case 'BOLD':
            return editor.chain().focus().toggleBold().run();
        case 'ITALIC':
            return editor.chain().focus().toggleItalic().run();
        case 'UNDERLINE':
            return editor.chain().focus().toggleUnderline().run();
        case 'STRIKETHROUGH':
            return editor.chain().focus().toggleStrike().run();
        case 'SUPERSCRIPT':
            return editor.chain().focus().toggleSuperscript().unsetSubscript().run();
        case 'SUBSCRIPT':
            return editor.chain().focus().toggleSubscript().unsetSuperscript().run();
        case 'MONOSPACE':
            return editor.chain().focus().toggleCode().run();
        case 'ADDITIONALS':
            return editor.chain().focus().run();
        default:
            return false;
    }
};

export const ItalicTextComponent = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);

    const { setCurrentState, value } = useSharedState<TInlineStyleValue, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <ToolbarBtn
            icon={icItalic}
            dataTest="wiki-toolbar-group_font-inline-style_ITALIC"
            tooltip={intl.formatMessage(messages.textItalic)}
            onClick={() => setCurrentState('ITALIC')}
            size="small"
            isActive={!!value?.ITALIC}
        />
    );
};
