import type { Editor } from '@tiptap/react';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_FONT_SIZE } from './Controls.constants';
import messages from '../CommonToolbar.messages';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import theme from '../../../Toolbar/MainMenu/ButtonGroups/groups.scss';
import { Select } from '../../../Select/Select.component';
import { fontSizeOptions } from '../../../../../../utils/configuration';
import classNames from 'classnames';
import { ClearValueBtn } from '../../../Toolbar/ButtonGroup/AddNewValueBtn/ClearValuesBtn/ClearValuesBtn.component';
import { AddNewValueBtn } from '../../../Toolbar/ButtonGroup/AddNewValueBtn/AddNewValueBtn.component';
import { TValue } from '../../../Toolbar/MainMenu/ButtonGroups/WikiGroups/wikiGroups.types';
import { StateObserver } from '../../common/sharedState.class';

const getFocusedBlock = (editor: Editor) => editor.getAttributes('textStyle').fontSize || DEFAULT_FONT_SIZE;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setFontSize(value).run();

const validateNumber = (rule: {}, value: string, callback: (msg?: string) => void) => {
    const pattern: RegExp = /^\d+$/;
    if (!pattern.test(value) || value === '0') {
        callback('not number');
    } else {
        callback();
    }
};

export const FontSizeComponent = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const [additionalValues, setAdditionalValue] = useState<TValue[]>([]);

    const handleAdd = (value: string) => {
        setAdditionalValue([
            ...additionalValues,
            {
                value: value,
                label: value,
            },
        ]);
    };

    return (
        <Select
            onChange={setCurrentState}
            value={
                [...fontSizeOptions, ...additionalValues].find((option) => option.value === value)?.label ||
                fontSizeOptions[6].label
            }
            placeholder=""
            originalTheme
            data-test="wiki-toolbar-group_select-font-size-button"
            wrapperClassName={classNames(theme.fontSizeContainer, theme.selectContainer)}
            selectTextClassName={theme.selectText}
            //tooltipTitle={intl.formatMessage(messages.fontSize)}
        >
            {[
                ...fontSizeOptions,
                ...additionalValues,
                !!additionalValues.length && {
                    value: 'del',
                    label: <ClearValueBtn onClicked={() => setAdditionalValue([])} />,
                },
                {
                    value: 'add',
                    label: (
                        <AddNewValueBtn
                            onAddClicked={(value) => handleAdd(value)}
                            title={intl.formatMessage(messages.formLabel)}
                            validationRules={[
                                { required: true, message: intl.formatMessage(messages.formValidationMessageRequired) },
                                {
                                    validator: validateNumber,
                                    message: intl.formatMessage(messages.formValidationMessageNumberOnly),
                                },
                            ]}
                        />
                    ),
                },
            ]
                .filter((option): option is TValue => !!option)
                .map((style) => {
                    return <Select.Option value={style.value} label={style.label} />;
                })}
        </Select>
    );
};
