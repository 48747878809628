import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../CommonToolbar.messages';
import { getSelectionEntity, getEntityRange } from 'draftjs-utils';
import { EditorState, Modifier, RichUtils } from 'draft-js';
import icLinkCreate from 'icons/toolbar/controls/ic-link-create.svg';
import { TWikiLink } from '@/models/tab.types';
import ControlsContext from '../Controls.context';
import { TRichEditorLink } from '../Toolbar.types';
import { useSharedState } from '../UseSharedState.hook';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';

type TLinkState = {
    action: string;
    link: TWikiLink;
};

const addLink =
    (type = 'internal') =>
    (editorState, value: TRichEditorLink) => {
        const linkBlockType = type === 'internal' ? 'LINK_CUSTOM' : 'LINK';
        const currentEntity = getSelectionEntity(editorState);
        let selection = editorState.getSelection();

        if (currentEntity) {
            const entityRange = getEntityRange(editorState, currentEntity);
            const isBackward = selection.getIsBackward();
            if (isBackward) {
                selection = selection.merge({
                    anchorOffset: entityRange.end,
                    focusOffset: entityRange.start,
                });
            } else {
                selection = selection.merge({
                    anchorOffset: entityRange.start,
                    focusOffset: entityRange.end,
                });
            }
        }
        const { url, title, text } = value;
        const notEmptyTitle = title || text || 'empty';

        const entityKey = editorState
            .getCurrentContent()
            .createEntity(linkBlockType, 'MUTABLE', {
                url,
                targetOption: '_blank',
            })
            .getLastCreatedEntityKey();

        let contentState = Modifier.replaceText(
            editorState.getCurrentContent(),
            selection,
            `${notEmptyTitle}`,
            editorState.getCurrentInlineStyle(),
            entityKey,
        );
        let newEditorState = EditorState.push(editorState, contentState, 'insert-characters');

        // insert a blank space after link
        selection = newEditorState.getSelection().merge({
            anchorOffset: selection.get('anchorOffset') + notEmptyTitle.length,
            focusOffset: selection.get('anchorOffset') + notEmptyTitle.length,
        });
        newEditorState = EditorState.acceptSelection(newEditorState, selection);
        contentState = Modifier.insertText(
            newEditorState.getCurrentContent(),
            selection,
            ' ',
            newEditorState.getCurrentInlineStyle(),
            undefined,
        );

        return EditorState.push(newEditorState, contentState, 'insert-characters');
    };

const addInternalLink = addLink('internal');
const addExternalLink = addLink('external');

const removeLink = (editorState: EditorState) => {
    const currentEntity = getSelectionEntity(editorState);
    let selection = editorState.getSelection();
    if (currentEntity) {
        const entityRange = getEntityRange(editorState, currentEntity);
        const isBackward = selection.getIsBackward();
        if (isBackward) {
            selection = selection.merge({
                anchorOffset: entityRange.end,
                focusOffset: entityRange.start,
            });
        } else {
            selection = selection.merge({
                anchorOffset: entityRange.start,
                focusOffset: entityRange.end,
            });
        }

        return RichUtils.toggleLink(editorState, selection, null);
    }

    return editorState;
};

const getChangedBlock = (editorState, value) => {
    const { action, link } = value;

    if (action === 'add_internal_link') {
        return addInternalLink(editorState, link);
    }
    if (action === 'add_external_link') {
        return addExternalLink(editorState, link);
    }
    if (action === 'remove_link') {
        return removeLink(editorState);
    }

    return editorState;
};

const LinkComponent = ({ openDialog }) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<TLinkState, TLinkState>({ stateObserver, getChangedBlock });

    const addInternalLinkHandler = () =>
        openDialog((link: TWikiLink) => setCurrentState({ action: 'add_internal_link', link }), { external: true });

    return (
        <ToolbarBtn
            icon={icLinkCreate}
            tooltip={intl.formatMessage(messages.setLink)}
            onClick={addInternalLinkHandler}
            dataTest="wiki-toolbar-group_add-link-button"
            size="small"
        />
    );
};

export default LinkComponent;
