import React, { useContext } from 'react';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { useIntl } from 'react-intl';
import { changeDepth, getSelectedBlock } from 'draftjs-utils';
import { CHECKABLE_LIST_ITEM, ORDERED_LIST_ITEM, UNORDERED_LIST_ITEM } from '../../common/constants';
import { toggleBlockType } from '../../common/editorState.utils';
import icListBulleted from 'icons/toolbar/controls/ic-format-list-bulleted.svg';
import { EditorState } from 'draft-js';
import { StateObserver } from '../../common/sharedState.class';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';
import messages from '../CommonToolbar.messages';

const getFocusedBlock = (editorState: EditorState) => getSelectedBlock(editorState).get('type');

const getChangedBlock = (editorState: EditorState, value: string) => {
    if (value === UNORDERED_LIST_ITEM) {
        return toggleBlockType(editorState, UNORDERED_LIST_ITEM);
    }
    if (value === ORDERED_LIST_ITEM) {
        return toggleBlockType(editorState, ORDERED_LIST_ITEM);
    }
    if (value === CHECKABLE_LIST_ITEM) {
        return toggleBlockType(editorState, CHECKABLE_LIST_ITEM);
    }
    if (value === 'indent') {
        return changeDepth(editorState, 1, 4);
    }

    return changeDepth(editorState, -1, 4);
};

export const BulletedListComponent = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <ToolbarBtn
            icon={icListBulleted}
            tooltip={intl.formatMessage(messages.unorderedList)}
            onClick={() => setCurrentState(UNORDERED_LIST_ITEM)}
            dataTest="wiki-toolbar-group_lists-button_bulleted"
            isActive={value === UNORDERED_LIST_ITEM}
            size="small"
        />
    );
};
