import type { Level } from '@tiptap/extension-heading';
import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../CommonToolbar.messages';
import { useSharedState } from '../UseSharedState.hook';
import { ControlsContext } from '../Controls.context';
//import theme from '../../../Toolbar/Toolbar.scss';
import theme from '../../../Toolbar/MainMenu/ButtonGroups/groups.scss';
import { StateObserver } from '../../common/sharedState.class';
import { Select } from '../../../Select/Select.component';
import { paragraphStyleOptions } from '../../../../../../utils/configuration';
import cx from 'classnames';

const getFocusedBlock = (editor: Editor) =>
    (editor.isActive('heading', { level: 1 }) && 'H1') ||
    (editor.isActive('heading', { level: 2 }) && 'H2') ||
    (editor.isActive('heading', { level: 3 }) && 'H3') ||
    (editor.isActive('heading', { level: 4 }) && 'H4') ||
    (editor.isActive('heading', { level: 5 }) && 'H5') ||
    (editor.isActive('heading', { level: 6 }) && 'H6') ||
    'P';

const getChangedBlock = (editor: Editor, action: string) => {
    switch (action) {
        case 'P':
            return editor.commands.clearNodes();
        case 'H1':
        case 'H2':
        case 'H3':
        case 'H4':
        case 'H5':
        case 'H6':
            return editor
                .chain()
                .focus()
                .toggleHeading({ level: Number(action[1]) as Level })
                .run();
        default:
            return false;
    }
};

export const ParagraphStyle = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <Select
            onChange={setCurrentState}
            value={intl.formatMessage(
                messages[
                    paragraphStyleOptions.find((option) => option.value === value)?.label ||
                        paragraphStyleOptions[0].label
                ],
            )}
            placeholder=""
            originalTheme
            data-test="wiki-toolbar-group_select-paragraph-style"
            wrapperClassName={cx(theme.paragraphContainer, theme.selectContainer)}
            selectTextClassName={theme.selectText}
            // tooltip={intl.formatMessage(messages.paragraphStyle)}
        >
            {paragraphStyleOptions.map((style) => {
                return (
                    <Select.Option
                        value={style.value}
                        label={<span style={style.style}>{intl.formatMessage(messages[style.label])}</span>}
                    />
                );
            })}
        </Select>
    );
};
