import type { Editor } from '@tiptap/react';
import React, { useContext, useState } from 'react';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { useSharedState } from '../UseSharedState.hook';
import { ColorResult, SketchPicker } from 'react-color';
import { ControlsContext } from '../Controls.context';
import icFillColor from '@/resources/icons/MainMenuFill-Color.svg';
import { DEFAULT_BG_COLOR } from './Controls.constants';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';

const getFocusedBlock = (editor: Editor) => editor.getAttributes('textStyle').backgroundColor || DEFAULT_BG_COLOR;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setBackgroundColor(value).run();

export const BGColorComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    const selectFillColorHandler = (colorObject: ColorResult) => {
        const { hex } = colorObject;
        setCurrentFillColor(hex);
        setCurrentState(hex);
    };
    const [currentFillColor, setCurrentFillColor] = useState(DEFAULT_BG_COLOR);
    return (
        <ToolbarBtn
            icon={icFillColor}
            dataTest="wiki-toolbar-group_font-bgColor-button"
            overlay={<SketchPicker color={currentFillColor} onChange={selectFillColorHandler} disableAlpha />}
            tooltip={intl.formatMessage(messages.colorFilling)}
            size="small"
        />
    );
};
