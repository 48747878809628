import { TWorkspaceTab } from '@/models/tab.types';
import { DashboardSelector } from '@/selectors/entities/dashboard.selectors';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DashboardWidget } from './DashboardWidget.component';
import theme from './Dashboard.scss';
import { changeCellSize, saveDashboard, selectWidget } from '@/actions/dashboard.actions';
import { TabsSelectors } from '@/selectors/tabs.selectors';
import { EditorMode } from '@/models/editorMode';
import cx from 'classnames';
import { NodeId } from '@/serverapi/api';
import { TWidget } from './Dashboard.types';
import { defaultCellSize } from './DashboardsConsts';
import { openDialog } from '@/actions/dialogs.actions';
import { DialogType } from '../DialogRoot/DialogRoot.constants';
import { DialogsSelectors } from '@/selectors/dialogs.selectors';
import { getDashboardSelectorKey } from './dashboardUtils';
import { useAutoSave } from '@/hooks/useAutoSave';
import { DashboardEditorToolbar } from '../MainMenu/components/DashboardEditorToolbar/DashboardEditorToolbar.component';

type TDashboardEditorProps = {
    tab: TWorkspaceTab;
};

export const DashboardEditor: FC<TDashboardEditorProps> = (props) => {
    const dashboardRef = useRef<HTMLDivElement>(null);
    const [cellSize, setCellSize] = useState<number>(defaultCellSize);
    const [widgetResizing, setWidgetResizing] = useState<boolean>(false);
    const [mouseDownOnWidget, setMouseDownOnWidget] = useState<boolean>(false);
    const dispatch = useDispatch();

    const {
        tab: { nodeId },
    } = props;

    const { verticalCount, horizontalCount } = useSelector(DashboardSelector.cellsCountById(nodeId));
    const widgets: TWidget[] = useSelector(DashboardSelector.widgetsbyId(nodeId));
    const selectedWidget: TWidget | undefined = useSelector(DashboardSelector.selectedWidget(nodeId));
    const editorMode: EditorMode | undefined = useSelector(TabsSelectors.getEditorModeById(nodeId));
    const activeTabId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const isVisibleDialog: boolean = useSelector(DialogsSelectors.isVisibleDialog);
    const isEditMode = editorMode === EditorMode.Edit;

    const saveDashboardAction = () => {
        dispatch(saveDashboard(nodeId));
    };

    useAutoSave({ isEditMode, nodeId, callback: saveDashboardAction });

    const keyDownHandler = (event: KeyboardEvent) => {
        if (
            (event.key === 'Delete' || event.key === 'Backspace') &&
            selectedWidget &&
            activeTabId.id === nodeId.id &&
            (event.target as HTMLInputElement).type !== 'text' &&
            !isVisibleDialog
        ) {
            dispatch(
                openDialog(DialogType.DASHBOARD_DELETE_WIDGET_DIALOG, {
                    parentNodeId: nodeId,
                    widgetId: selectedWidget.id,
                    widgetName: selectedWidget.name,
                }),
            );
        }
    };

    useEffect(() => {
        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    });

    const updateCellSize = () => {
        if (dashboardRef.current) {
            const newCellSize = dashboardRef.current.offsetWidth / verticalCount;
            if (newCellSize) {
                setCellSize(newCellSize);
                dispatch(changeCellSize(newCellSize, nodeId));
            }
        }
    };

    const resizeObserver = new ResizeObserver(() => {
        updateCellSize();
    });

    useEffect(() => {
        if (dashboardRef.current) {
            resizeObserver.observe(dashboardRef.current);
        }
        updateCellSize();

        return () => {
            if (dashboardRef.current) {
                resizeObserver.unobserve(dashboardRef.current);
            }
        };
    }, [dashboardRef, verticalCount, horizontalCount]);

    const editorClickHandler = () => {
        if (!widgetResizing && !mouseDownOnWidget) {
            dispatch(selectWidget(nodeId, ''));
        } else {
            setWidgetResizing(false);
            setMouseDownOnWidget(false);
        }
    };

    const setIsWidgetResizing = useCallback((isResizing: boolean) => {
        setWidgetResizing(isResizing);
    }, []);

    const setIsMouseDownOnWidget = useCallback((isMouseDownOnWidget: boolean) => {
        setMouseDownOnWidget(isMouseDownOnWidget);
    }, []);

    const cellsCount = useMemo(() => ({ verticalCount, horizontalCount }), [verticalCount, horizontalCount]);

    const roundedCellsSize = Math.round(cellSize);

    return (
        <>
            <DashboardEditorToolbar />
            <div className={theme.dashboardEditorContainer} data-test="dashboard-editor_container">
                <div
                    ref={dashboardRef}
                    style={{
                        backgroundSize: `${roundedCellsSize}px ${roundedCellsSize}px`,
                        height: `${Math.ceil(roundedCellsSize * horizontalCount)}px`,
                    }}
                    className={cx(theme.dashboardEditor, isEditMode && theme.dashboardBackground)}
                    id={getDashboardSelectorKey(nodeId)}
                    onClick={editorClickHandler}
                >
                    {widgets.map((widget) => (
                        <DashboardWidget
                            disable={!isEditMode}
                            key={widget.id}
                            cellSize={roundedCellsSize}
                            widget={widget}
                            nodeId={nodeId}
                            selected={selectedWidget?.id === widget.id}
                            cellsCount={cellsCount}
                            setIsWidgetResizing={setIsWidgetResizing}
                            setIsMouseDownOnWidget={setIsMouseDownOnWidget}
                        />
                    ))}
                </div>
            </div>
        </>
    );
};
