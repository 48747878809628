import icMoveLevelUp from '@/resources/icons/ic-ribbon-send-front.svg';
import icMoveLevelTop from '@/resources/icons/ic-ribbon-send-top.svg';
import icMoveLevelDown from '@/resources/icons/ic-ribbon-send-back.svg';
import icMoveLevelBottom from '@/resources/icons/ic-ribbon-send-bottom.svg';

export enum MoveLayer {
    Up,
    Top,
    Down,
    Bottom,
}

export const MoveLayerIcons = {
    Up: icMoveLevelUp,
    Top: icMoveLevelTop,
    Down: icMoveLevelDown,
    Bottom: icMoveLevelBottom,
};
