import React from 'react';
import { Alignment, AlignmentIcons } from '../../../../../../models/alignment';
import messages from './AlignSelect.messages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { alignAction, distributeAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import { ToolbarSelect } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarSelect';

export const AlignSelect = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const tooltip: string = useSelector(EditorToolbarSelectors.getAlignSelectTooltip);
    const disabled: boolean = useSelector(EditorToolbarSelectors.getAlignSelectDisabled);
    const distributeDisabled: boolean = useSelector(EditorToolbarSelectors.getDistributerBtnDisabled);

    const setAlign = (alignment: Alignment) => {
        dispatch(alignAction(alignment));
    };

    const setAlignHandler = (alignment: Alignment) => {
        if (alignment === Alignment.DistributeHor) {
            dispatch(distributeAction(true));
        }
        if (alignment === Alignment.DistributeVert) {
            dispatch(distributeAction(false));
        }
        setAlign(alignment);
    };

    const alignButtons = Object.getOwnPropertyNames(Alignment)
        .filter((prop) => isNaN(parseInt(prop)))
        .map((key) => {
            const distributeHVDisabled: boolean =
                (key === 'DistributeHor' || key === 'DistributeVert') && distributeDisabled;
            return (
                <ToolbarBtn
                    icon={AlignmentIcons[key]}
                    tooltip={
                        distributeHVDisabled
                            ? intl.formatMessage(messages.selectMinThreeElementsOnCanvas)
                            : intl.formatMessage(messages[key])
                    }
                    onClick={() => setAlignHandler(Alignment[key])}
                    disabled={distributeHVDisabled}
                />
            );
        });

    return (
        <ToolbarSelect
            options={alignButtons}
            icon={AlignmentIcons.Left}
            size="small"
            disabled={disabled}
            tooltip={tooltip}
            dataTest="graph-general-toolbar_align-button"
        />
    );
};
