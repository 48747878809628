import React from 'react';
import { ToolbarBtn } from '../../../ButtonGroup/ToolbarBtn.component';
import icOpenFormat from '@/resources/icons/formatPanel.svg';
import { useSelector } from 'react-redux';
import { EditorToolbarSelectors } from '../../../../../../../selectors/editorToolbar.selectors';

export const OpenFormatBtn = () => {
    const disabled: boolean = useSelector(EditorToolbarSelectors.getOpenFormatBtnDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getOpenFormatBtnTooltip);

    return <ToolbarBtn icon={icOpenFormat} size="small" disabled={disabled} tooltip={tooltip} />;
};
