import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import { getSelectedBlock } from 'draftjs-utils';
import messages from '../CommonToolbar.messages';
import icListIndentDescrease from 'icons/toolbar/controls/ic-indent-decrease.svg';
import icListIndentIncrease from 'icons/toolbar/controls/ic-indent-increase.svg';
import { setBlockIndent, removeIndent } from '../../common/indentBlocks.utils';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { ToolbarSelect } from '../../../Toolbar/ButtonGroup/ToolbarSelect';
import { StateObserver } from '../../common/sharedState.class';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';

const getFocusedBlock = (editorState) => getSelectedBlock(editorState).get('type');

const getChangedBlock = (editorState, value) => {
    if (value === 'indent') {
        return setBlockIndent(editorState);
    }

    return removeIndent(editorState);
};

const indentMap = {
    indent: {
        action: 'indent',
        tooltip: messages.indent,
        icon: icListIndentIncrease,
        dataTest: 'wiki-toolbar-group_font-indent-button_increase',
    },
    outdent: {
        action: 'outdent',
        tooltip: messages.outdent,
        icon: icListIndentDescrease,
        dataTest: 'wiki-toolbar-group_font-indent-button_descrease',
    },
};

const IndentComponent = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);

    const { setCurrentState } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    const onChangeIndent = (activeActionId: string) => () => {
        setCurrentState(activeActionId);
    };

    const indentTextButtons = Object.keys(indentMap).map((key) => {
        const button = (
            <ToolbarBtn
                icon={indentMap[key].icon}
                tooltip={intl.formatMessage(indentMap[key].tooltip)}
                onClick={onChangeIndent(indentMap[key].action)}
                dataTest={indentMap[key].dataTest}
            />
        );
        return button;
    });

    return (
        <ToolbarSelect
            icon={indentMap.indent.icon}
            dataTest="wiki-toolbar-group_font-indent-button_popover"
            tooltip={intl.formatMessage(messages.textIndentation)}
            options={indentTextButtons}
        />
    );
};

export default IndentComponent;
