import type { Editor } from '@tiptap/react';
import React, { FC, useContext } from 'react';
//import { useIntl } from 'react-intl';
import { DEFAULT_FONT_FAMILY } from './Controls.constants';
import { useSharedState } from '../UseSharedState.hook';
import { ControlsContext } from '../Controls.context';
import theme from '../../../Toolbar/MainMenu/ButtonGroups/groups.scss';
import { Select } from '../../../Select/Select.component';
import { StateObserver } from '../../common/sharedState.class';
import { fontFamilyOptions } from '../../../../../../utils/configuration';
import classNames from 'classnames';

const getFocusedBlock = (editor: Editor) => editor.getAttributes('textStyle').fontFamily || DEFAULT_FONT_FAMILY;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setFontFamily(value).run();

type TFontSizeComponent = {
    className?: string;
};

export const FontFamilyComponent: FC<TFontSizeComponent> = () => {
    //const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <Select
            onChange={setCurrentState}
            value={fontFamilyOptions.find((option) => option.value === value)?.label || fontFamilyOptions[0].label}
            placeholder=""
            originalTheme
            data-test="font-family_select"
            wrapperClassName={classNames(theme.fontFamilyContainer, theme.selectContainer)}
            selectTextClassName={theme.selectText}
            // tooltip={intl.formatMessage(messages.fontFamily)}
        >
            {fontFamilyOptions.map((style) => {
                return <Select.Option value={style.value} label={style.label} />;
            })}
        </Select>
    );
};
