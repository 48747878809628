import * as React from 'react';
import { useIntl } from 'react-intl';
import { ShapeStyle, ShapeStyleIcons } from '../../../../../../models/Symbols.constants';
import messages from './ShapeSelect.messages';
import icShape from '@/resources/icons/icShape.svg';
import { useDispatch, useSelector } from 'react-redux';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { dragShapeAction } from '../../../../../../actions/editor.actions';
import { getShape } from '../../../../../../models/Symbols.utils';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import { ToolbarSelect } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarSelect';

export const ShapeSelect = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getAddShapeDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getaddShapeBtnTooltip);

    const onPasteShapeAction = (shapeStyle: ShapeStyle) => dispatch(dragShapeAction(getShape(shapeStyle)));

    const shapeButtons: JSX.Element[] = Object.keys(ShapeStyle).map((key) => {
        return (
            <ToolbarBtn
                icon={ShapeStyleIcons[key]}
                tooltip={intl.formatMessage(messages[ShapeStyle[key]])}
                onClick={() => dispatch(onPasteShapeAction(ShapeStyle[key]))}
            />
        );
    });

    return (
        <ToolbarSelect
            icon={icShape}
            options={shapeButtons}
            size="small"
            dataTest="general-panel-paste_shapes"
            disabled={disabled}
            tooltip={tooltip}
        />
    );
};
