import { EditModeButton } from '@/modules/MainMenu/components/GraphGeneralToolbar/ToolbarItems/EditModeButton/EditModeButton';
import React, { useRef } from 'react';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { observerUseEffect } from '../../../UIKit/components/Toolbar/Toolbars/hooks/observerUseEffect';
import { MainMenuWrapper } from '../../../UIKit/components/Toolbar/MainMenu/MainMenuWrapper.component';
import { RefreshButton } from './Buttons/Refresh.component';
import { DeleteButton } from './Buttons/Delete.component';
import { NodeId } from '../../../../serverapi/api';

type TReportEditorToolbar = {
    selectedNodeIds: NodeId[];
    showDeleteBtn?: boolean;
};

export const ReportEditorToolbar = (props: TReportEditorToolbar) => {
    const { selectedNodeIds, showDeleteBtn } = props;
    const mainMenuRef = useRef<HTMLTableCellElement | null>(null);
    const { menuWidth, countOfHiddenGroups } = observerUseEffect(mainMenuRef);
    const btns: JSX.Element[] = [
        <ButtonGroup isMainMenuBtn>
            <EditModeButton />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <RefreshButton />
        </ButtonGroup>,
    ].concat(
        showDeleteBtn
            ? [
                  <ButtonGroup isMainMenuBtn>
                      <DeleteButton selectedNodeIds={selectedNodeIds} />
                  </ButtonGroup>,
              ]
            : [],
    );

    return (
        <MainMenuWrapper
            mainMenuToolbar={btns}
            mainMenuRef={mainMenuRef}
            countOfHiddenGroups={countOfHiddenGroups}
            menuWidth={menuWidth}
            withFormatBtn={false}
        />
    );
};
