import type { Editor } from '@tiptap/react';
import React, { useContext, useState } from 'react';
import { useIntl } from 'react-intl';
import { DEFAULT_LINE_HEIGHT } from './Controls.constants';
import messages from '../CommonToolbar.messages';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import theme from '../../../Toolbar/MainMenu/ButtonGroups/groups.scss';
import { Select } from '../../../Select/Select.component';
import { lineHeightOptions } from '../../../../../../utils/configuration';
import classNames from 'classnames';
import { ClearValueBtn } from '../../../Toolbar/ButtonGroup/AddNewValueBtn/ClearValuesBtn/ClearValuesBtn.component';
import { AddNewValueBtn } from '../../../Toolbar/ButtonGroup/AddNewValueBtn/AddNewValueBtn.component';
import { TValue } from '../../../Toolbar/MainMenu/ButtonGroups/WikiGroups/wikiGroups.types';

// TODO getAttributes('paragraph') работает только для типа 'paragraph'
// нужно предусмотреть возможность задавать lineHeight для всех типов
const getFocusedBlock = (editor: Editor) => editor.getAttributes('paragraph').lineHeight || DEFAULT_LINE_HEIGHT;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setLineHeight(value).run();

const validateNumber = (rule: {}, value: string, callback: (msg?: string) => void) => {
    if (isNaN(+value) && +value < 0) {
        callback('not number');
    } else {
        callback();
    }
};

export const LineHeightComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const [additionalValues, setAdditionalValue] = useState<TValue[]>([]);
    const intl = useIntl();
    const handleAdd = (value: string) => {
        setAdditionalValue([
            ...additionalValues,
            {
                value: value,
                label: value,
            },
        ]);
    };

    return (
        <Select
            onChange={setCurrentState}
            value={
                [...lineHeightOptions, ...additionalValues].find((option) => option.value === value)?.label ||
                lineHeightOptions[6].label
            }
            placeholder=""
            originalTheme
            data-test="wiki-toolbar-group_select-line-interval"
            wrapperClassName={classNames(theme.lineHeightContainer, theme.selectContainer)}
            selectTextClassName={theme.selectText}
            //tooltipTitle={intl.formatMessage(messages.lineHeight)}
        >
            {[
                ...lineHeightOptions,
                ...additionalValues,
                !!additionalValues.length && {
                    value: 'del',
                    label: <ClearValueBtn onClicked={() => setAdditionalValue([])} />,
                },
                {
                    value: 'add',
                    label: (
                        <AddNewValueBtn
                            onAddClicked={(value) => handleAdd(value)}
                            title={intl.formatMessage(messages.lineHeight)}
                            validationRules={[
                                { required: true, message: intl.formatMessage(messages.lineHeightMessageRequired) },
                                {
                                    validator: validateNumber,
                                    message: intl.formatMessage(messages.lineHeightMessageNumberOnly),
                                },
                            ]}
                        />
                    ),
                },
            ]
                .filter((option): option is TValue => !!option)
                .map((style) => {
                    return <Select.Option value={style.value} label={style.label} />;
                })}
        </Select>
    );
};
