import React from 'react';
import { useIntl } from 'react-intl';
import { MoveTo, MoveToIcons } from '../../../../../../models/move-to';
import messages from './MoveToSelect.messages';
import { useDispatch, useSelector } from 'react-redux';
import { moveToAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import { ToolbarSelect } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarSelect';

export const MoveToSelect = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const tooltip: string = useSelector(EditorToolbarSelectors.getMoveToSelectTooltip());
    const disabled: boolean = useSelector(EditorToolbarSelectors.getMoveToSelectDisabled());
    const goToDecompositionDisabled: boolean = useSelector(EditorToolbarSelectors.getGoToDecompositionBtnDisabled());
    const goToObjectInTreeDisabled: boolean = useSelector(EditorToolbarSelectors.getGoToObjectInTreeBtnDisabled);

    const goToButtons = [
        <ToolbarBtn
            icon={MoveToIcons.Decomposition}
            tooltip={intl.formatMessage(messages.decomposition)}
            onClick={() => dispatch(moveToAction(MoveTo.Decomposition))}
            dataTest="graph-general-toolbar_goto_Decomposition"
            disabled={goToDecompositionDisabled}
        />,
        <ToolbarBtn
            icon={MoveToIcons.ObjectInTree}
            tooltip={intl.formatMessage(messages.objectInTree)}
            onClick={() => dispatch(moveToAction(MoveTo.ObjectInTree))}
            dataTest="graph-general-toolbar_goto_ObjectInTtee"
            disabled={goToObjectInTreeDisabled}
        />,
    ];

    return (
        <ToolbarSelect
            options={goToButtons}
            icon={MoveToIcons.Noop}
            size="small"
            tooltip={tooltip}
            disabled={disabled}
            dataTest="main-panel_button-go-to"
        />
    );
};
