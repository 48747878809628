import React, { useContext } from 'react';
import { getSelectedBlocksMetadata } from 'draftjs-utils';
import { DEFAULT_LINE_HEIGHT } from './Controls.constants';
import { setSelectedBlocksData } from '../../common/contentBlocks.utils';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import theme from '../../../Toolbar/MainMenu/ButtonGroups/groups.scss';
import { Select } from '../../../Select/Select.component';
import classNames from 'classnames';

const getFocusedBlock = (editorState) => {
    return getSelectedBlocksMetadata(editorState).get('line-height') || DEFAULT_LINE_HEIGHT;
};

const getChangedBlock = (editorState, lineHeight) => {
    return setSelectedBlocksData(editorState, { 'line-height': lineHeight });
};

const baseOptions = [
    {
        value: '1',
        label: '1',
    },
    {
        value: '1.2',
        label: '1.2',
    },
    {
        value: '1.5',
        label: '1.5',
    },
    {
        value: '1.75',
        label: '1.75',
    },
    {
        value: '2',
        label: '2',
    },
    {
        value: '3',
        label: '3',
    },
    {
        value: '3.5',
        label: '3.5',
    },
    {
        value: '4',
        label: '4',
    },
    {
        value: '4.5',
        label: '4.5',
    },
    {
        value: '5',
        label: '5',
    },
    {
        value: '5.5',
        label: '5.5',
    },
    {
        value: '6',
        label: '6',
    },
];

const LineHeightComponent = () => {
    //const intl = useIntl();
    const stateObserver = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <Select
            onChange={setCurrentState}
            value={value}
            placeholder=""
            originalTheme
            data-test="wiki-toolbar-group_select-line-interval"
            wrapperClassName={classNames(theme.lineHeightContainer, theme.selectContainer)}
            selectTextClassName={theme.selectText}
            // tooltip={intl.formatMessage(messages.lineHeight)}
        >
            {baseOptions.map((fontFamily) => {
                return <Select.Option value={fontFamily.value} label={fontFamily.value} />;
            })}
        </Select>
    );
};

export default LineHeightComponent;
