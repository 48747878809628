import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToolbarBtn } from '../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import { reportDeleteNodes } from '../../../actions/report.actions';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { useIntl } from 'react-intl';
import messages from '../../ReportEditorFilterBar/ReportEditorFilterBar.messages';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { NodeId } from '../../../../../serverapi/api';
import icDelete from '@/resources/icons/Deleted.svg';

type TDeleteButton = {
    selectedNodeIds: NodeId[];
};

export const DeleteButton = (props: TDeleteButton) => {
    const { selectedNodeIds = [] } = props;
    const dispatch = useDispatch();
    const intl = useIntl();
    const activeTabId = useSelector(TabsSelectors.getActiveTabId);
    const onDelete = () => {
        dispatch(reportDeleteNodes(activeTabId, selectedNodeIds));
    };

    const deleteHandler = () => {
        dispatch(openDialog(DialogType.DELETE_ELEMETS_IN_REPORT_DIALOG, { onDelete }));
    };

    return (
        <ToolbarBtn
            disabled={selectedNodeIds.length === 0}
            icon={icDelete}
            size="small"
            onClick={deleteHandler}
            tooltip={intl.formatMessage(messages.deleteMessage)}
        />
    );
};
