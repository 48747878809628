import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Icon } from '../../Icon/Icon.component';
import ArrowDown from '@/resources/icons/UIKit/Arrow_Down.svg';
import theme from './ToolbarSelect.scss';
import { createPortal } from 'react-dom';
import { ButtonGroup } from './ButtonGroup.component';
import { useClickOutside } from '../../Select/useClickOutside';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { TToolbarSelect } from './ToolbarSelect.types';

type TDropdownOptions = null | {
    x: number;
    y: number;
};

export const ToolbarSelect = (props: TToolbarSelect) => {
    const { icon, options, disabled, tooltip, onClick } = props;
    const [isActive, setIsActive] = useState<boolean>(false);
    const handleClickHeader = () => {
        if (onClick) {
            onClick();
        }
        setIsActive((prevState) => !prevState);
    };
    const [dropdownOptions, setDropdownOptions] = useState<TDropdownOptions>(null);

    const containerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);
    const selectButtonRef: React.MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const portalRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null);

    const recalculateDropdownOptions = () => {
        const clientRects: DOMRect | undefined = selectButtonRef.current?.getBoundingClientRect();
        const portalRects: DOMRect | undefined = portalRef.current?.getBoundingClientRect();

        setDropdownOptions({
            y: window.innerHeight - (clientRects?.bottom || 0) - 6,
            x:
                (clientRects?.left || 0) + (portalRects?.width || 0) < window.innerWidth
                    ? (clientRects?.left || 0) - 6
                    : (clientRects?.right || 0) - (portalRects?.width || 0) + 6,
        });
    };

    useClickOutside(
        containerRef,
        () => {
            isActive && setIsActive(false);
        },
        portalRef,
    );
    const windowRsizeHandle = () => {
        setIsActive(false);
    };
    useEffect(() => {
        window.addEventListener('resize', windowRsizeHandle);

        return () => {
            window.removeEventListener('resize', windowRsizeHandle);
        };
    }, []);

    useLayoutEffect(() => {
        if (isActive) recalculateDropdownOptions();
    }, [isActive]);

    return (
        <div className={theme.selectorContainer}>
            <div ref={containerRef} className={classNames(theme.selectHeader, disabled ? theme.disabled : '')}>
                <Tooltip title={tooltip}>
                    <span>
                        <button
                            ref={selectButtonRef}
                            type="button"
                            onClick={handleClickHeader}
                            className={classNames(theme.headerBtn)}
                            disabled={disabled}
                            onMouseDown={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <Icon spriteSymbol={icon} disabled={disabled} />
                            <Icon className={theme.arrowDown} spriteSymbol={ArrowDown} disabled={disabled} />
                        </button>
                    </span>
                </Tooltip>
            </div>
            {isActive &&
                createPortal(
                    <div
                        ref={portalRef}
                        data-test={`${props['data-test']}_dropdown`}
                        className={`${theme.dropdownContainer}`}
                        style={{
                            top: `-${dropdownOptions?.y}px`,
                            left: dropdownOptions?.x,
                            width: 'fit-content',
                        }}
                    >
                        <ButtonGroup>{options}</ButtonGroup>
                    </div>,
                    document.body,
                )}
        </div>
    );
};
