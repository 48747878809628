import React, { useRef } from 'react';
import { EditModeButton } from '@/modules/MainMenu/components/GraphGeneralToolbar/ToolbarItems/EditModeButton/EditModeButton';
import { OpenSettings } from './Buttons/OpenSettings.component';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { Refresh } from './Buttons/Refresh.component';
import { Paste } from './Buttons/Paste.component';
import { Copy } from './Buttons/Copy.component';
import { PasteEmptyLaneTop } from './Buttons/PasteEmptyLaneTop.component';
import { PasteEmptyLaneBottom } from './Buttons/PasteEmptyLaneBottom.component';
import { PasteColLeft } from './Buttons/PasteColLeft.component';
import { PasteColRight } from './Buttons/PasteColRight.component';
import { observerUseEffect } from '../../../UIKit/components/Toolbar/Toolbars/hooks/observerUseEffect';
import { MainMenuWrapper } from '../../../UIKit/components/Toolbar/MainMenu/MainMenuWrapper.component';

export const MatrixEditorToolbar = () => {
    const mainMenuRef = useRef<HTMLTableCellElement | null>(null);
    const { menuWidth, countOfHiddenGroups } = observerUseEffect(mainMenuRef);
    const btns: JSX.Element[] = [
        <ButtonGroup isMainMenuBtn>
            <EditModeButton />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <OpenSettings />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <Refresh />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <Paste />
            <Copy />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <PasteEmptyLaneTop />
            <PasteEmptyLaneBottom />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <PasteColLeft />
            <PasteColRight />
        </ButtonGroup>,
    ];

    return (
        <MainMenuWrapper
            mainMenuToolbar={btns}
            mainMenuRef={mainMenuRef}
            countOfHiddenGroups={countOfHiddenGroups}
            menuWidth={menuWidth}
            withFormatBtn={false}
        />
    );
};
