import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icText from '@/resources/icons/icText.svg';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import { dragShapeAction } from '../../../../../../actions/editor.actions';
import { SymbolConstants } from '../../../../../../models/Symbols.constants';

export const AddTextBtn = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getAddShapeDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getaddTextBtnTooltip);

    const onPasteTextAction = () => {
        dispatch(dragShapeAction(SymbolConstants.shape));
    };

    return (
        <ToolbarBtn
            icon={icText}
            size="small"
            dataTest="general-panel-paste_text"
            disabled={disabled}
            onClick={onPasteTextAction}
            tooltip={tooltip}
        />
    );
};
