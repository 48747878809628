import React from 'react';
import { ToolbarBtn } from '../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import icSettingsMatrix from '@/resources/icons/toolbar/ic-settingmatrix.svg';
import { openDialog } from '../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../DialogRoot/DialogRoot.constants';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { NodeId } from '../../../../../serverapi/api';
import { MatrixEditorSelectors } from '../../../../Matrix/selectors/matrixEditor.selectors';

export const OpenSettings = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(MatrixEditorSelectors.getOpenSettingsDisabled);
    const tooltip: string = useSelector(MatrixEditorSelectors.getOpenSettingsTooltip);
    const activeTabNodeId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const openSettings = () => {
        dispatch(openDialog(DialogType.MATRIX_SETTINGS_DIALOG, { nodeId: activeTabNodeId }));
    };

    return (
        <ToolbarBtn icon={icSettingsMatrix} size="small" disabled={disabled} onClick={openSettings} tooltip={tooltip} />
    );
};
