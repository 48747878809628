import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icSampleFormat from '../../../../../../resources/icons/sample-format.svg';
import { formatByExampleAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';

export const FormatButton = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getSampleFormatBtnDisabled());
    const tooltip: string = useSelector(EditorToolbarSelectors.getSampleFormatBtnTooltip());

    const onFormatAction = () => {
        dispatch(formatByExampleAction());
    };

    return (
        <ToolbarBtn
            icon={icSampleFormat}
            size="small"
            dataTest="general-toolbar_format-btn"
            disabled={disabled}
            onClick={onFormatAction}
            tooltip={tooltip}
        />
    );
};
