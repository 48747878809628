import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../CommonToolbar.messages';
import { TablePicker, TTablePickerSize } from 'UIKit';
import icTable from 'icons/toolbar/controls/ic-table.svg';
import { insertTable } from '../../Editor/renderers/Table/TableContentsBlocks.utils';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';

const getChangedBlock = (editorState, size) => {
    return insertTable(editorState, size);
};

const TableComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, TTablePickerSize>({ stateObserver, getChangedBlock });
    const onChangeHandler = (tableSize: TTablePickerSize) => {
        setCurrentState(tableSize);
    };

    return (
        <ToolbarBtn
            icon={icTable}
            tooltip={intl.formatMessage(messages.insertTable)}
            overlay={<TablePicker onChange={onChangeHandler} />}
            dataTest="wiki-toolbar-group_add-table-button"
            size="small"
        />
    );
};

export default TableComponent;
