import React from 'react';
import { useIntl } from 'react-intl';
import { PickOut, PickOutIcons } from '../../../../../../models/pick-out';
import messages from './PickOutSelect.messages';
import { useDispatch, useSelector } from 'react-redux';
import { distributeAction, pickOutAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { Alignment } from '../../../../../../models/alignment';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import { ToolbarSelect } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarSelect';

export const PickOutSelect = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const tooltip: string = useSelector(EditorToolbarSelectors.getPickOutSelectTooltip());

    const setAlign = (alignment: Alignment) => {
        if (alignment === Alignment.DistributeHor) {
            dispatch(distributeAction(true));
        }
        if (alignment === Alignment.DistributeVert) {
            dispatch(distributeAction(false));
        }
        setAlign(alignment);
    };

    const selectButtons = Object.getOwnPropertyNames(PickOut)
        .filter((prop) => isNaN(parseInt(prop)))
        .filter((key) => key !== 'PickDefault')
        .map((key) => {
            return (
                <ToolbarBtn
                    icon={PickOutIcons[key]}
                    tooltip={intl.formatMessage(messages[key])}
                    onClick={() => dispatch(pickOutAction(PickOut[key]))}
                />
            );
        });

    return (
        <ToolbarSelect
            options={selectButtons}
            icon={PickOutIcons.PickDefault}
            size="small"
            tooltip={tooltip}
            dataTest="graph-general-toolbar_select-button"
        />
    );
};
