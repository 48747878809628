import icAlignTop from '@/resources/icons/ic-align-top.svg';
import icAlignBottom from '@/resources/icons/ic-align-bottom.svg';
import icAlignLeft from '@/resources/icons/ic-align-left.svg';
import icAlignCenterVert from '@/resources/icons/ic-align-center-vert.svg';
import icAlignRight from '@/resources/icons/ic-align-right.svg';
import icAlignCenterHorz from '@/resources/icons/ic-align-center-horz.svg';
import icDistributeHor from '@/resources/icons/ic-distribute-hor.svg';
import icDistributeVert from '@/resources/icons/ic-distribute-vert.svg';

export enum Alignment {
    Left,
    CenterVert,
    Right,
    Top,
    CenterHorz,
    Bottom,
    DistributeHor,
    DistributeVert,
}

export const AlignmentIcons = {
    Left: icAlignLeft,
    CenterVert: icAlignCenterVert,
    Right: icAlignRight,
    Top: icAlignTop,
    CenterHorz: icAlignCenterHorz,
    Bottom: icAlignBottom,
    DistributeHor: icDistributeHor,
    DistributeVert: icDistributeVert,
};
