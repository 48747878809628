import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icCopy from '../../../../../../resources/icons/copy.svg';
import { copyAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';

export const CopyButton = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getCopyBtnDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getCopyBtnTooltip);

    return (
        <ToolbarBtn
            icon={icCopy}
            size="small"
            dataTest="general-toolbar_copy-btn"
            disabled={disabled}
            onClick={() => dispatch(copyAction())}
            tooltip={tooltip}
        />
    );
};
