import React, { useContext } from 'react';
import icAdditionalFont from '@/resources/icons/MainMenuFont-Additional.svg';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { EditorState, RichUtils } from 'draft-js';
import { getSelectionInlineStyle } from 'draftjs-utils';
import { useIntl } from 'react-intl';
import { StateObserver } from '../../common/sharedState.class';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';
import icSubscript from 'icons/toolbar/controls/ic-font-subscript.svg';
import icSuperscript from 'icons/toolbar/controls/ic-font-superscript.svg';
import icMonospace from 'icons/toolbar/controls/ic-font-monospace.svg';
import icStrikeThrough from 'icons/toolbar/controls/ic-font-strikethrough.svg';
import { ToolbarSelect } from '../../../Toolbar/ButtonGroup/ToolbarSelect';
import messages from '../CommonToolbar.messages';

type TInlineStyleValue = {
    [key: string]: boolean;
};

type TActionDefinition = {
    action: string;
    tooltip: { id: string; defaultMessage: string };
    icon: any;
};

type TExtraActionsDefinitions = {
    [name: string]: TActionDefinition;
};

const getFocusedBlock = (editorState: EditorState) => getSelectionInlineStyle(editorState);
const getChangedBlock = (editorState: EditorState, style: string) => {
    return RichUtils.toggleInlineStyle(editorState, style);
};

const extraActionsDefinitions: TExtraActionsDefinitions = {
    STRIKETHROUGH: {
        action: 'STRIKETHROUGH',
        tooltip: messages.textStrikeThrough,
        icon: icStrikeThrough,
    },
    SUPERSCRIPT: {
        action: 'SUPERSCRIPT',
        tooltip: messages.superscript,
        icon: icSuperscript,
    },
    SUBSCRIPT: {
        action: 'SUBSCRIPT',
        tooltip: messages.subscript,
        icon: icSubscript,
    },
    CODE: {
        action: 'CODE',
        tooltip: messages.monospace,
        icon: icMonospace,
    },
};

export const InlineStylesComponent = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);

    const { setCurrentState, value } = useSharedState<TInlineStyleValue, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    const onExtraChange = (activeActionId: string) => () => {
        setCurrentState(activeActionId);
    };

    const selectButtons = Object.getOwnPropertyNames(extraActionsDefinitions).map((key) => {
        const button = (
            <ToolbarBtn
                icon={extraActionsDefinitions[key].icon}
                dataTest={`wiki-general-toolbar_${key}`}
                tooltip={intl.formatMessage(extraActionsDefinitions[key].tooltip)}
                onClick={onExtraChange(extraActionsDefinitions[key].action)}
                isActive={!!value?.[key]}
            />
        );

        return button;
    });

    return (
        <ToolbarSelect
            icon={icAdditionalFont}
            dataTest="wiki-toolbar-group_font-inline-style-button_popover"
            tooltip={intl.formatMessage(messages.moreInlineStyles)}
            options={selectButtons}
        />
    );
};
