import React from 'react';
import clearIcon from '@/resources/icons/icBin.svg';
import theme from '../AddNewValueBtn.scss';
import { Button } from '../../../../Button/Button.component';

type TAddNewValueBtn = { onClicked: () => void };

export const ClearValueBtn = (props: TAddNewValueBtn) => {
    const { onClicked } = props;

    return (
        <div className={theme.btnContainer}>
            <Button dataTest={'user-managment_add-user-button'} onClick={onClicked} icon={clearIcon} size="small" />
        </div>
    );
};
