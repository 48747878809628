import React, { FC, useContext } from 'react';
import { toggleCustomInlineStyle, getSelectionCustomInlineStyle } from 'draftjs-utils';
import { useSharedState } from '../UseSharedState.hook';
import ControlsContext from '../Controls.context';
import theme from '../../../Toolbar/MainMenu/ButtonGroups/groups.scss';
import { fontFamilyOptions } from '../../../../../../utils/configuration';
import classNames from 'classnames';
import { Select } from '../../../Select/Select.component';

const getFocusedBlock = (editorState) => {
    const fontFamilyStyle = getSelectionCustomInlineStyle(editorState, ['FONTFAMILY']);
    if (fontFamilyStyle) {
        return fontFamilyStyle.FONTFAMILY?.replace('fontfamily-', '');
    }

    return undefined;
};

const getChangedBlock = (editorState, value) => toggleCustomInlineStyle(editorState, 'fontFamily', value);

type TFontSizeComponent = {
    className?: string;
};

const FontFamilyComponent: FC<TFontSizeComponent> = ({ className = '' }) => {
    const stateObserver = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <Select
            onChange={setCurrentState}
            value={value || fontFamilyOptions[0].value}
            placeholder=""
            originalTheme
            data-test="font-family_select"
            wrapperClassName={classNames(theme.fontFamilyContainer, theme.selectContainer)}
            selectTextClassName={theme.selectText}
            //tooltip={intl.formatMessage(messages.fontFamily)}
        >
            {fontFamilyOptions.map((fontFamily) => {
                return <Select.Option value={fontFamily.value} label={fontFamily.value} />;
            })}
        </Select>
    );
};

export default FontFamilyComponent;
