import React from 'react';
import { Popover, Tooltip } from 'antd';
//import { GREY_COLOR_MATRIX } from '../../constants';
import theme from './ToolbarBtn.scss';
import classnames from 'classnames';
import { TToolbarBtn } from './toolbarBtns.types';

const DEFAULT_SIZE = 16;

const GREY_COLOR_MATRIX = '0.4 0.4 0.4 0 0 0.4 0.4 0.4 0 0 0.4 0.4 0.4 0 0 0 0 0 1 0';

export const ToolbarBtn = (props: TToolbarBtn) => {
    const { icon, onClick, dataTest, tooltip, disabled, key, isActive, size = 'normal', overlay } = props;
    const buttonClass: string = classnames(theme.button, {
        [theme.active]: isActive,
        [theme.disabled]: disabled,
        [theme.normal]: size === 'normal',
        [theme.small]: size === 'small',
    });

    return (
        <Tooltip title={tooltip}>
            <Popover content={overlay} trigger="click" placement="bottom">
                <button
                    type={'button'}
                    key={key}
                    onClick={!disabled ? onClick : undefined}
                    data-test={dataTest}
                    className={buttonClass}
                    onMouseDown={(e) => {
                        e.preventDefault();
                    }}
                >
                    {icon ? (
                        <svg
                            width={icon?.width || DEFAULT_SIZE}
                            height={icon?.height || DEFAULT_SIZE}
                            viewBox={icon.viewBox}
                        >
                            <defs>
                                <filter id="grayscale">
                                    <feColorMatrix type="matrix" values={GREY_COLOR_MATRIX} />
                                </filter>
                            </defs>
                            <use xlinkHref={`#${icon.id}`} filter={disabled ? 'url(#grayscale)' : undefined} />
                        </svg>
                    ) : (
                        ''
                    )}
                </button>
            </Popover>
        </Tooltip>
    );
};
