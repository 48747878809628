import React, { useState } from 'react';
import icDots from '@/resources/icons/MainMenuDots.svg';
import { ToolbarDotsBtn } from '../../../ButtonGroup/ToolbarDotsBtn.component';
import { useIntl } from 'react-intl';
import messages from '@/modules/MainMenu/messages/CommonToolbar.messages';

type TShowHiddenBtn = {
    hiddenGroups: JSX.Element[];
    containerWidth: number;
    mainMenuRef: HTMLTableCellElement;
};

export const ShowHiddenBtn = (props: TShowHiddenBtn) => {
    const { hiddenGroups, mainMenuRef, containerWidth } = props;
    const intl = useIntl();
    const [isActive, setIsactive] = useState<boolean>(false);
    const tooltip: string = isActive
        ? intl.formatMessage(messages.hideButtons)
        : intl.formatMessage(messages.showButtons);

    const paddingRight: number = parseInt(getComputedStyle(mainMenuRef).paddingRight) || 0;
    const dotsBtnContainerWidth: number = mainMenuRef.children?.[1]?.getBoundingClientRect().width || 0;
    const dotsBtn: Element | undefined = mainMenuRef.children[1].children?.[0];
    const dotsBtnWidth: number | undefined = dotsBtn?.getBoundingClientRect().width || 0;

    const rightOffset: number = dotsBtn
        ? paddingRight + dotsBtnContainerWidth - dotsBtnWidth - parseInt(getComputedStyle(dotsBtn).marginLeft)
        : paddingRight;

    return (
        <ToolbarDotsBtn
            isActive={isActive}
            icon={icDots}
            onClick={() => {
                setIsactive((value) => !value);
            }}
            tooltip={tooltip}
            hiddenGroups={hiddenGroups}
            containerWidth={containerWidth}
            rightOffset={rightOffset}
        />
    );
};
