import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import arrowRight from '../../../../../../resources/icons/arrow-right.svg';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { NodeId } from '../../../../../../serverapi/api';
import { TabsSelectors } from '../../../../../../selectors/tabs.selectors';
import { workspaceTabSetParams } from '../../../../../../actions/tabs.actions';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';

export const FilterOutputBtn = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getFilterOutputBtnDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getFilterOutputBtnTooltip);
    const activeTabNodeId: NodeId | undefined = useSelector(TabsSelectors.getActiveTabId);
    const nodeFilterOutput: boolean = useSelector(TabsSelectors.getActiveTabNodeFilterOutput);

    const handleNodeFilterOutputChange = () => {
        if (activeTabNodeId) dispatch(workspaceTabSetParams(activeTabNodeId, { nodeFilterOutput: !nodeFilterOutput }));
    };

    return (
        <ToolbarBtn
            icon={arrowRight}
            size="small"
            dataTest="main-panel_checkbox-outgoing"
            disabled={disabled}
            onClick={handleNodeFilterOutputChange}
            tooltip={tooltip}
            isActive={nodeFilterOutput && !disabled}
        />
    );
};
