import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icClearFormat from '../../../../../../resources/icons/clear-format.svg';
import { setCellsDefaultStyle } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';

export const ClearFormatButton = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getClearFormatBtnDisabled());
    const tooltip: string = useSelector(EditorToolbarSelectors.getClearFormatBtnTooltip());

    const onSetCellDefaultStyleAction = () => {
        dispatch(setCellsDefaultStyle());
    };

    return (
        <ToolbarBtn
            icon={icClearFormat}
            size="small"
            dataTest="general-toolbar_clear-format-btn"
            disabled={disabled}
            onClick={onSetCellDefaultStyleAction}
            tooltip={tooltip}
        />
    );
};
