import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import icLeft from 'icons/toolbar/controls/ic-text-align-left.svg';
import icCenter from 'icons/toolbar/controls/ic-text-align-center.svg';
import icRight from 'icons/toolbar/controls/ic-text-align-right.svg';
import { useIntl } from 'react-intl';
import icAlignFill from 'icons/toolbar/controls/ic-text-align-fill.svg';
import { useSharedState } from '../UseSharedState.hook';
import { ControlsContext } from '../Controls.context';
import messages from '../CommonToolbar.messages';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';
import { ToolbarSelect } from '../../../Toolbar/ButtonGroup/ToolbarSelect';
import { TToolbarBtn } from '../../../Toolbar/ButtonGroup/toolbarBtns.types';
import { TextAlign } from '@/utils/configuration';

const getFocusedBlock = (editor: Editor) =>
    (editor.isActive({ textAlign: TextAlign.LEFT }) && TextAlign.LEFT) ||
    (editor.isActive({ textAlign: TextAlign.RIGHT }) && TextAlign.RIGHT) ||
    (editor.isActive({ textAlign: TextAlign.CENTER }) && TextAlign.CENTER) ||
    (editor.isActive({ textAlign: TextAlign.JUSTIFY }) && TextAlign.JUSTIFY) ||
    '';

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setTextAlign(value).run();

const alignMap = {
    left: {
        icon: icLeft,
        action: TextAlign.LEFT,
        tooltip: messages.textLeft,
        dataTest: 'wiki-toolbar-group_font-align-button_group_left',
    },
    center: {
        icon: icCenter,
        action: TextAlign.CENTER,
        tooltip: messages.textCenter,
        dataTest: 'wiki-toolbar-group_font-align-button_group_center',
    },
    right: {
        icon: icRight,
        action: TextAlign.RIGHT,
        tooltip: messages.textRight,
        dataTest: 'wiki-toolbar-group_font-align-button_group_right',
    },
    justify: {
        icon: icAlignFill,
        action: TextAlign.JUSTIFY,
        tooltip: messages.textJustify,
        dataTest: 'wiki-toolbar-group_font-align-button_group_toWidth',
    },
};

export const TextAlignComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const intl = useIntl();

    const selectAlignHandler = (newAlign: string) => () => {
        setCurrentState(newAlign);
    };

    const alignTextButtons: React.ReactElement<TToolbarBtn, typeof ToolbarBtn>[] = Object.keys(alignMap).map((key) => {
        return (
            <ToolbarBtn
                icon={alignMap[key].icon}
                tooltip={intl.formatMessage(alignMap[key].tooltip)}
                onClick={selectAlignHandler(alignMap[key].action)}
                dataTest={alignMap[key].dataTest}
                isActive={value === key}
            />
        );
    });

    return (
        <ToolbarSelect
            icon={alignMap[value || TextAlign.LEFT].icon}
            dataTest="wiki-toolbar-group_font-align-button_group_popover"
            tooltip={intl.formatMessage(messages.textAlign)}
            options={alignTextButtons}
            size="small"
        />
    );
};
