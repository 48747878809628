import type { Editor } from '@tiptap/react';
import icListBulleted from 'icons/toolbar/controls/ic-format-list-bulleted.svg';
import { useIntl } from 'react-intl';
import { useContext } from 'react';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { StateObserver } from '../../common/sharedState.class';
import messages from '../CommonToolbar.messages';
import React from 'react';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';

const BULLET_LIST_ITEM = 'bullet-list-item';

const ORDERED_LIST_ITEM = 'ordered-list-item';

const TASK_LIST_ITEM = 'task-list-item';

const SINK_LIST_ITEM = 'sink-list-item';

const LIFT_LIST_ITEM = 'lift-list-item';

type TListsValue = {
    [key: string]: boolean;
};

const itemsIdMap = {
    [BULLET_LIST_ITEM]: 'listItem',
    [ORDERED_LIST_ITEM]: 'listItem',
    [TASK_LIST_ITEM]: 'taskItem',
};

const getFocusedBlock = (editor: Editor) => ({
    [BULLET_LIST_ITEM]: editor.isActive('bulletList'),
    [ORDERED_LIST_ITEM]: editor.isActive('orderedList'),
    [TASK_LIST_ITEM]: editor.isActive('taskList'),
});

const getChangedBlock = (editor: Editor, action: string) => {
    const activeLists = getFocusedBlock(editor);
    const activeListItemKey = Object.keys(activeLists).find((key) => activeLists[key]) || 'listItem';

    switch (action) {
        case BULLET_LIST_ITEM:
            return editor.chain().focus().toggleBulletList().run();
        case ORDERED_LIST_ITEM:
            return editor.chain().focus().toggleOrderedList().run();
        case TASK_LIST_ITEM:
            return editor.chain().focus().toggleTaskList().run();
        case SINK_LIST_ITEM:
            return editor.chain().focus().sinkListItem(itemsIdMap[activeListItemKey]).run();
        case LIFT_LIST_ITEM:
            return editor.chain().focus().liftListItem(itemsIdMap[activeListItemKey]).run();
        default:
            return false;
    }
};

export const BulletListComponent = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);
    const { setCurrentState, value } = useSharedState<TListsValue, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const onChange = (listType) => () => {
        return setCurrentState(listType);
    };

    return (
        <ToolbarBtn
            icon={icListBulleted}
            tooltip={intl.formatMessage(messages.bulletList)}
            onClick={onChange(BULLET_LIST_ITEM)}
            dataTest={'wiki-toolbar-group_lists-button_bulleted'}
            isActive={!!value?.[BULLET_LIST_ITEM]}
            size="small"
        />
    );
};
