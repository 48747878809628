import type { TImageAttributes } from '../../Editor/extensions/image.types';
import type { Editor } from '@tiptap/react';
import React, { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import { v4 as uuid } from 'uuid';
import messages from '../CommonToolbar.messages';
import icImage from 'icons/toolbar/controls/ic-attach-image.svg';
import icFile from 'icons/toolbar/controls/ic-file.svg';
import icLinkToSite from 'icons/toolbar/controls/ic-link-to-site.svg';
import icModelPreview from '@/resources/icons/addAttributeType.svg';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { insertImage } from '../../common/helpers';
import { ImageNodeType } from '../../Editor/extensions/constants';
import { ToolbarSelect } from '../../../Toolbar/ButtonGroup/ToolbarSelect';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';

type TImagesState = {
    action: string;
    attrs: Partial<TImageAttributes>;
};

const getChangedBlock = (editor: Editor, value: TImagesState) => {
    const { action, attrs } = value;

    if (action === 'FILE' && attrs.id) {
        return insertImage(editor, ImageNodeType.WIKI_IMAGE_NODE_NAME, attrs);
    }

    if (action === 'EXTERNAL') {
        return insertImage(editor, ImageNodeType.EXTERNAL_IMAGE_NODE_NAME, attrs);
    }

    if (action === 'MODEL_PREVIEW' && attrs.id) {
        return insertImage(editor, ImageNodeType.MODEL_IMAGE_NODE_NAME, attrs);
    }

    return false;
};

type TImageComponent = {
    openImageFileDialog: (submitCallback: (file: File, attrs: Partial<TImageAttributes>) => void) => void;
    openExternalImageDialog: (submitCallback: (attrs: Partial<TImageAttributes>) => void) => void;
    openModelPreviewDialog: (submitCallback: (attrs: Partial<TImageAttributes>) => void) => void;
    uploadImage: (file: File, id: string) => { id: string };
};

export const ImagesComponent: FC<TImageComponent> = ({
    openImageFileDialog,
    openExternalImageDialog,
    openModelPreviewDialog,
    uploadImage,
}) => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, TImagesState>({ stateObserver, getChangedBlock });

    const addImage = (action: string) => {
        if (action === 'FILE') {
            const onReceiveImage = (file: File, { alt, title }: Partial<TImageAttributes>) => {
                const id = uuid();

                uploadImage(file, id);
                setCurrentState({ action, attrs: { id, alt, title } });
            };

            return openImageFileDialog(onReceiveImage);
        }

        if (action === 'EXTERNAL') {
            const onAddImage = (attrs: Partial<TImageAttributes>) => {
                setCurrentState({ action, attrs });
            };

            return openExternalImageDialog(onAddImage);
        }

        if (action === 'MODEL_PREVIEW') {
            const onChooseModel = (attrs: Partial<TImageAttributes>) => {
                setCurrentState({ action, attrs });
            };

            openModelPreviewDialog(onChooseModel);
        }
    };

    const menuItems = [
        <ToolbarBtn
            tooltip={intl.formatMessage(messages.insertImageFile)}
            icon={icFile}
            dataTest="wiki-toolbar-group_add-image-button_add-file-image"
            onClick={() => addImage('FILE')}
        />,
        <ToolbarBtn
            tooltip={intl.formatMessage(messages.insertExternalImage)}
            icon={icLinkToSite}
            dataTest="wiki-toolbar-group_add-image-button_add-external-image"
            onClick={() => addImage('EXTERNAL')}
        />,
        <ToolbarBtn
            tooltip={intl.formatMessage(messages.insertModelPreview)}
            icon={icModelPreview}
            dataTest="wiki-toolbar-group_add-image-button_add-model-preview"
            onClick={() => addImage('MODEL_PREVIEW')}
        />,
    ];

    return (
        <ToolbarSelect
            icon={icImage}
            options={menuItems}
            tooltip={intl.formatMessage(messages.insertImage)}
            dataTest={'wiki-toolbar-group_add-image-button'}
            size="small"
        />
    );
};
