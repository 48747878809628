import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icCut from '../../../../../../resources/icons/cut.svg';
import { cutAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';

export const CutButton = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getCutBtnDisabled());
    const tooltip: string = useSelector(EditorToolbarSelectors.getCutBtnTooltip);

    return (
        <ToolbarBtn
            icon={icCut}
            size="small"
            dataTest="general-toolbar_cut-btn"
            disabled={disabled}
            onClick={() => dispatch(cutAction())}
            tooltip={tooltip}
        />
    );
};
