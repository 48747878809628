import React, { useContext } from 'react';
import ControlsContext from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { changeDepth, isListBlock, getBlockBeforeSelectedBlock, getSelectedBlock } from 'draftjs-utils';
import { CHECKABLE_LIST_ITEM, ORDERED_LIST_ITEM, UNORDERED_LIST_ITEM } from '../../common/constants';
import { toggleBlockType } from '../../common/editorState.utils';
import icListIndentIncrease from 'icons/toolbar/controls/ic-indent-increase.svg';
import { ContentBlock, EditorState } from 'draft-js';
import { StateObserver } from '../../common/sharedState.class';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';

const isExtendedListBlock = (block) => {
    const type = block.get('type');

    return type === CHECKABLE_LIST_ITEM || isListBlock(block);
};

const getFocusedBlock = (editorState: EditorState) => getSelectedBlock(editorState).get('type');

const getChangedBlock = (editorState: EditorState, value: string) => {
    if (value === UNORDERED_LIST_ITEM) {
        return toggleBlockType(editorState, UNORDERED_LIST_ITEM);
    }
    if (value === ORDERED_LIST_ITEM) {
        return toggleBlockType(editorState, ORDERED_LIST_ITEM);
    }
    if (value === CHECKABLE_LIST_ITEM) {
        return toggleBlockType(editorState, CHECKABLE_LIST_ITEM);
    }
    if (value === 'indent') {
        return changeDepth(editorState, 1, 4);
    }

    return changeDepth(editorState, -1, 4);
};

const getIsIndentDisabled = (editorState: EditorState) => {
    const currentBlock: ContentBlock = getSelectedBlock(editorState);
    const previousBlock: ContentBlock = getBlockBeforeSelectedBlock(editorState);
    if (
        !previousBlock ||
        !isExtendedListBlock(currentBlock) ||
        previousBlock.get('type') !== currentBlock.get('type') ||
        previousBlock.get('depth') < currentBlock.get('depth')
    ) {
        return true;
    }

    return false;
};

export const IncreaseListComponent = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);
    const { setCurrentState, editorState } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const onChange = (listType) => () => {
        return setCurrentState(listType);
    };
    const isIndentDisabled = getIsIndentDisabled(editorState);

    return (
        <ToolbarBtn
            icon={icListIndentIncrease}
            tooltip={intl.formatMessage(messages.indent)}
            onClick={onChange('indent')}
            dataTest="wiki-toolbar-group_lists-button_indent"
            disabled={isIndentDisabled}
            size="small"
        />
    );
};
