import React, { useRef } from 'react';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { DashboardEditModeButton } from './Buttons/DashboardEditMode.component';
import { GridButton } from './Buttons/GridButton.component';
import { observerUseEffect } from '../../../UIKit/components/Toolbar/Toolbars/hooks/observerUseEffect';
import { MainMenuWrapper } from '../../../UIKit/components/Toolbar/MainMenu/MainMenuWrapper.component';

export const DashboardEditorToolbar = () => {
    const mainMenuRef = useRef<HTMLTableCellElement | null>(null);
    const { menuWidth, countOfHiddenGroups } = observerUseEffect(mainMenuRef);
    const btns = [
        <ButtonGroup isMainMenuBtn>
            <DashboardEditModeButton />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <GridButton />
        </ButtonGroup>,
    ];
    return (
        <MainMenuWrapper
            mainMenuToolbar={btns}
            mainMenuRef={mainMenuRef}
            countOfHiddenGroups={countOfHiddenGroups}
            menuWidth={menuWidth}
            withFormatBtn={false}
        />
    );
};
