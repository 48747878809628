// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AddNewValueBtn__btnContainer__gYKIW{width:100%;margin-left:-4px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Toolbar/ButtonGroup/AddNewValueBtn/AddNewValueBtn.scss"],"names":[],"mappings":"AAAA,qCACI,UAAA,CACA,gBAAA","sourcesContent":[".btnContainer {\r\n    width: 100%;\r\n    margin-left: -4px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"btnContainer": "AddNewValueBtn__btnContainer__gYKIW"
};
export default ___CSS_LOADER_EXPORT___;
