import type { TRichTextEditorToolbarProps } from './RichTextEditorToolbar.types';
import React, { FC, useRef } from 'react';
import { getStateObserver } from '../common/sharedState.class';
import { ControlsContext } from './Controls.context';
import { TextAlignComponent } from './controls/TextAlign.component';
import { BGColorComponent } from './controls/BGColor.component';
import { FontColorComponent } from './controls/FontColor.component';
import { FontFamilyComponent } from './controls/FontFamily.component';
import { FontSizeComponent } from './controls/FontSize.component';
import { ImagesComponent } from './controls/Images.component';
import { TableComponent } from './controls/Table.component';
import { LinksComponent } from './controls/Links.component';
import { EraserComponent } from './controls/Eraser.component';
import { LineHeightComponent } from './controls/LineHeight.component';
import { IndentComponent } from './controls/Indent.component';
import { ParagraphStyle } from './controls/ParagraphStyle.component';
import { MacrosComponent } from './controls/Macros.component';
import { BulletListComponent } from './controls/BulletListComponent.component';
import { OrderedListCompnonet } from './controls/OrderedListCompnonet.component';
import { TaskListComponent } from './controls/TaskListComponent.component';
import { SinkListItemComponent } from './controls/SinkListItemComponent.component';
import { LiftListItemComponent } from './controls/LiftListItemComponent.component';
import { BoldTextComponent } from './controls/BoldTextComponent.component';
import { ItalicTextComponent } from './controls/ItalicTextComponent.component';
import { MoreLineStylesComponent } from './controls/MoreLineStylesComponent.component';
import { UnderLineTextComponent } from './controls/WikiUnderLineText.component';
import { ButtonGroup } from '../../Toolbar/ButtonGroup/ButtonGroup.component';
import { MainMenuWrapper } from '../../Toolbar/MainMenu/MainMenuWrapper.component';
import { observerUseEffect } from '../../Toolbar/Toolbars/hooks/observerUseEffect';
import { UndoButton } from '../../../../MainMenu/components/GraphGeneralToolbar/ToolbarItems/UndoBtn/UndoButton.component';
import { RedoButton } from '../../../../MainMenu/components/GraphGeneralToolbar/ToolbarItems/RedoBtn/RedoButton.component';

export const RichTextEditorToolbar: FC<TRichTextEditorToolbarProps> = ({ id, handlers, hideIndent }) => {
    const stateObserverStateObserver = getStateObserver(id);
    const { openLinkDialog, openImageDialog, openInsertImageLinkDialog, openChooseModelDialog, uploadImage } = handlers;
    const mainMenuRef = useRef<HTMLTableCellElement | null>(null);
    const { menuWidth, countOfHiddenGroups } = observerUseEffect(mainMenuRef);
    const btns: JSX.Element[] = [
        <ButtonGroup isMainMenuBtn>
            <UndoButton />
            <RedoButton />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <ParagraphStyle />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <FontFamilyComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <FontSizeComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <LineHeightComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <FontColorComponent />
            <BGColorComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <BoldTextComponent />
            <ItalicTextComponent />
            <UnderLineTextComponent />
            <MoreLineStylesComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <TextAlignComponent />
            <IndentComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <BulletListComponent />
            <OrderedListCompnonet />
            <TaskListComponent />
            {!hideIndent ? <SinkListItemComponent /> : null}
            {!hideIndent ? <LiftListItemComponent /> : null}
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <MacrosComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <LinksComponent openDialog={openLinkDialog} />
            <ImagesComponent
                uploadImage={uploadImage}
                openImageFileDialog={openImageDialog}
                openExternalImageDialog={openInsertImageLinkDialog}
                openModelPreviewDialog={openChooseModelDialog}
            />
            <TableComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <EraserComponent />
        </ButtonGroup>,
    ];
    return (
        <ControlsContext.Provider value={stateObserverStateObserver}>
            <MainMenuWrapper
                mainMenuToolbar={btns}
                mainMenuRef={mainMenuRef}
                countOfHiddenGroups={countOfHiddenGroups}
                menuWidth={menuWidth}
                withFormatBtn={false}
            />
        </ControlsContext.Provider>
    );
};
