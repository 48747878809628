import React, { useRef } from 'react';
import { AlignSelect } from './ToolbarItems/AlignSelect/AlignSelect.component';
import { MoveLayerSelect } from './ToolbarItems/MoveLayerSelect/MoveLayerSelect.component';
import { InsertSpaceSelect } from './ToolbarItems/InsertSpaceSelect/InsertSpaceSelect.component';
import { MoveToSelect } from './ToolbarItems/MoveToSelect/MoveToSelect.component';
import { PickOutSelect } from './ToolbarItems/PickOutSelect/PickOutSelect.component';
import { InsertionButton } from './ToolbarItems/InsertionButton/InsertionButton.component';
import { EditModeButton } from './ToolbarItems/EditModeButton/EditModeButton';
import { SaveButton } from './ToolbarItems/SaveButton/SaveButton';
import { CopyButton } from './ToolbarItems/CopyButton/CopyButton.component';
import { CutButton } from './ToolbarItems/CutButton/CutButton.component';
import { PrintButton } from './ToolbarItems/PrintButton/PrintButton.component';
import { DeleteButton } from './ToolbarItems/DeleteButton/DeleteButton.component';
import { PropertyButton } from './ToolbarItems/PropertyButton/PropertyButton.component';
import { DecompositionButton } from './ToolbarItems/DecompositionButton/DecompositionButton.component';
import { UpdateButton } from './ToolbarItems/UpdateButton/UpdateButton.component';
import { SearchButton } from './ToolbarItems/SearchButton/SearchButton.component';
import { SwitchGridLayout } from './ToolbarItems/SwitchGridLayout/SwitchGridLayout.component';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { AddImageBtn } from './ToolbarItems/AddImageBtn/AddImageBtn.component';
import { AddTextBtn } from './ToolbarItems/AddTextBtn/AddTextBtn.component';
import { FiltrationBtn } from './ToolbarItems/FiltrationBtn/FiltrationBtn.component';
import { observerUseEffect } from '../../../UIKit/components/Toolbar/Toolbars/hooks/observerUseEffect';
import { MainMenuWrapper } from '../../../UIKit/components/Toolbar/MainMenu/MainMenuWrapper.component';
import { FilterInputBtn } from './ToolbarItems/FilterInput/FilterInput.component';
import { FilterOutputBtn } from './ToolbarItems/FilterOutputBtn/FilterOutputBtn.compnent';
import { ClearFormatButton } from './ToolbarItems/ClearFormatButton/ClearFormatButton.component';
import { FormatButton } from './ToolbarItems/FormatButton/FormatButton.component';
import { ShapeSelect } from './ToolbarItems/ShapeSelect/ShapeSelect.component';
import { UndoButton } from './ToolbarItems/UndoBtn/UndoButton.component';
import { RedoButton } from './ToolbarItems/RedoBtn/RedoButton.component';

export const GraphGeneralToolbar = () => {
    // const [isEmptyClipboard, setIsEmptyClipboard] = useState<boolean>(MxClipboard.isEmpty());

    // useEffect(() => {
    //     if (nodeId && activeGraph) {
    //         const clipboardUpdate = () => {
    //             setIsEmptyClipboard(MxClipboard.isEmpty());
    //         };
    //         activeGraph.selectionModel.addListener('CLIPBOARD_UPDATE', clipboardUpdate);

    //         return () => {
    //             activeGraph.selectionModel.removeListener(clipboardUpdate);
    //         };
    //     }

    //     return noop;
    // }, [nodeId, activeGraph]);

    const mainMenuRef = useRef<HTMLTableCellElement | null>(null);
    const { menuWidth, countOfHiddenGroups } = observerUseEffect(mainMenuRef);

    const btns = [
        <ButtonGroup isMainMenuBtn>
            <EditModeButton />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <UndoButton />
            <RedoButton />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <UpdateButton />
            <SearchButton />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <SaveButton />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <InsertionButton />
            <CopyButton />
            <PrintButton />
            <CutButton />
            <DeleteButton />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <SwitchGridLayout />
            <InsertSpaceSelect />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <AlignSelect />
            <MoveLayerSelect />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <PropertyButton />
            <DecompositionButton />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <PickOutSelect />
            <MoveToSelect />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <FiltrationBtn />
            <FilterInputBtn />
            <FilterOutputBtn />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <AddImageBtn />
            <AddTextBtn />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <ShapeSelect />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <FormatButton />
            <ClearFormatButton />
        </ButtonGroup>,
    ];

    return (
        <MainMenuWrapper
            mainMenuToolbar={btns}
            mainMenuRef={mainMenuRef}
            countOfHiddenGroups={countOfHiddenGroups}
            menuWidth={menuWidth}
            withFormatBtn={true}
        />
    );
};
