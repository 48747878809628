import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import icEraser from 'icons/toolbar/controls/ic-eraser.svg';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { useSharedState } from '../UseSharedState.hook';
import { ControlsContext } from '../Controls.context';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';

const getChangedBlock = (editor: Editor) =>
    editor.chain().clearNodes().unsetAllMarks().unsetIndent().unsetLineHeight().unsetTextAlign().run();

export const EraserComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<void, React.SyntheticEvent<HTMLElement>>({
        stateObserver,
        getChangedBlock,
    });

    return (
        <ToolbarBtn
            tooltip={intl.formatMessage(messages.eraser)}
            icon={icEraser}
            onClick={setCurrentState}
            dataTest="wiki-toolbar-group_eraser-button"
            size="small"
        />
    );
};
