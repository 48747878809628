import React, { useContext } from 'react';
import { getSelectedBlocksMetadata } from 'draftjs-utils';
import icLeft from 'icons/toolbar/controls/ic-text-align-left.svg';
import icCenter from 'icons/toolbar/controls/ic-text-align-center.svg';
import icRight from 'icons/toolbar/controls/ic-text-align-right.svg';
import { useIntl } from 'react-intl';
import icAlignFill from 'icons/toolbar/controls/ic-text-align-fill.svg';
import { setSelectedBlocksData } from '../../common/contentBlocks.utils';
import { useSharedState } from '../UseSharedState.hook';
import ControlsContext from '../Controls.context';
import messages from '../CommonToolbar.messages';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';
import { StateObserver } from '../../common/sharedState.class';
import { ToolbarSelect } from '../../../Toolbar/ButtonGroup/ToolbarSelect';

const alignMap = {
    left: {
        icon: icLeft,
        action: 'left',
        tooltip: messages.textLeft,
        dataTest: 'wiki-toolbar-group_font-align-button_group_left',
    },
    center: {
        icon: icCenter,
        action: 'center',
        tooltip: messages.textCenter,
        dataTest: 'wiki-toolbar-group_font-align-button_group_center',
    },
    right: {
        icon: icRight,
        action: 'right',
        tooltip: messages.textRight,
        dataTest: 'wiki-toolbar-group_font-align-button_group_right',
    },
    justify: {
        icon: icAlignFill,
        action: 'justify',
        tooltip: messages.textToWidth,
        dataTest: 'wiki-toolbar-group_font-align-button_group_toWidth',
    },
};
const getFocusedBlock = (editorState) => getSelectedBlocksMetadata(editorState).get('text-align');
const getChangedBlock = (editorState, value) => setSelectedBlocksData(editorState, { 'text-align': value });

const TextAlignComponent = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);

    const { setCurrentState: setCurrentStateForAlign, value: alignValue } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    const selectAlignHandler = (newAlign: string) => () => {
        setCurrentStateForAlign(newAlign);
    };
    const alignTextButtons = Object.keys(alignMap).map((key) => {
        const button = (
            <ToolbarBtn
                icon={alignMap[key].icon}
                tooltip={intl.formatMessage(alignMap[key].tooltip)}
                onClick={selectAlignHandler(alignMap[key].action)}
                dataTest={alignMap[key].dataTest}
                isActive={alignValue === key}
            />
        );
        return button;
    });

    return (
        <ToolbarSelect
            icon={alignMap[alignValue || 'left'].icon}
            dataTest="wiki-toolbar-group_font-align-button_group_popover"
            tooltip={intl.formatMessage(messages.textAlign)}
            options={alignTextButtons}
        />
    );
};

export default TextAlignComponent;
