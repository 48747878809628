import icOff from '@/resources/icons/ic-node-filter-off.svg';
import icSingle from '@/resources/icons/ic-node-filter-single.svg';
import icMany from '@/resources/icons/ic-node-filter-many.svg';
import icNotSame from '@/resources/icons/ic-node-filter-not-same.svg';

export enum BpmNodeFilterBase {
    Off = 1,
    Single = 2,
    Many = 3,
    NotSame = 4,
}

export const BpmNodeFilterBaseIcons = {
    Off: icOff,
    Single: icSingle,
    Many: icMany,
    NotSame: icNotSame,
};
