import React, { FC } from 'react';
import { Layout, Popover } from 'antd';
import theme from './Header.scss';
import { Icon } from '../../../UIKit/components/Icon/Icon.component';
// import iconUndo from '@/resources/icons/ic-app-undo.svg';
// import iconRedo from '@/resources/icons/ic-app-redo.svg';
import icLineType from '@/resources/icons/icLineType.svg';
import fullscreen from '@/resources/icons/fullscreen.svg';
import shrink from '@/resources/icons/shrink.svg';
import closeIcon from '@/resources/icons/closeIconWhite.svg';
import { Logo } from '../Logo/Logo.component';
import { THeaderProps } from './header.types';
import iLoc from '@/resources/icons/localization.svg';
import { LocalesService } from '@/services/LocalesService';
import { LocaleSelect } from '../LocaleSelect/LocaleSelect.component';
import { HEADER_CONTAINER_ID } from '@/mxgraph/components/BPMPopupMenu.types';
import { InitialsCircle } from '../../../UIKit/components/InitialsCircle/InitialsCircle.component';
import aip from '../../../../resources/icons/aip.svg';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import { ExperimentalFeatures } from '@/models/ExperimentalFeatures';
import { AssistantInterfacePanel } from '@/components/aip/AssistantInterfacePanel.component';
import { useSelector } from 'react-redux';
import { getIsStarted } from '@/selectors/app.selector';
import { useKeyDownListener } from '@/utils/useKeyDownListener';
const { Header: ANTDHeader } = Layout;

export const Header: FC<THeaderProps> = (props) => {
    const [isVisibleAipPannel, setIsVisibleAipPannel] = React.useState<boolean>(false);
    const isSearchAssistantEnabled: boolean = ExperimentalFeatures.isSearchAssistantEnabled();
    const isAppStarted = useSelector(getIsStarted);

    const {
        onClose,
        onToggleSize,
        onMinimize,
        isMaximized,
        onClickInitialsCircle,
        isVisibleButtonActions,
        onLocaleSelect,
        locale,
        isStarted,
        isEnLocaleAllowed,
        clientIcon,
    } = props;

    const onOpenAipPanel = () => {
        setIsVisibleAipPannel(true);
    };

    useKeyDownListener(
        ['ctrlKey'],
        'KeyH',
        () => {
            if (isSearchAssistantEnabled && isAppStarted) {
                setIsVisibleAipPannel((prevState) => !prevState);
            }
        },
        undefined,
        true,
    );

    useKeyDownListener([], 'Escape', () => {
        if (isSearchAssistantEnabled && isAppStarted) {
            setIsVisibleAipPannel(false);
        }
    });

    return (
        <div className={theme.container} id={HEADER_CONTAINER_ID}>
            {isVisibleAipPannel && <AssistantInterfacePanel setIsVisibleAipPannel={setIsVisibleAipPannel} />}

            <ANTDHeader>
                <Logo isStarted={isStarted} clientIcon={clientIcon} />
                {!isStarted && (
                    <div className={theme.headerItems}>
                        <Popover
                            content={
                                <LocaleSelect
                                    locale={locale}
                                    onLocaleSelect={onLocaleSelect}
                                    isChangeLocaleAllowed={isEnLocaleAllowed}
                                />
                            }
                            trigger="click"
                        >
                            <div data-test="locale-switch-button" className={theme.localization}>
                                <Icon className={theme.titleContentIcon} spriteSymbol={iLoc} />
                                <div>{LocalesService.convertToString(locale)}</div>
                            </div>
                        </Popover>
                    </div>
                )}

                <div className={theme.rightButtonsWrapper}>
                    {isStarted && (
                        <div className={theme.circleContainer}>
                            {isSearchAssistantEnabled && (
                                <div className={theme.aipIconWrapper}>
                                    <Icon onClick={onOpenAipPanel} spriteSymbol={aip} />
                                </div>
                            )}

                            <InitialsCircle click={onClickInitialsCircle} />
                        </div>
                    )}

                    {isVisibleButtonActions && (
                        <div className={theme.controls}>
                            <Button
                                visualStyle={{ type: 'text' }}
                                size="small"
                                onClick={onMinimize}
                                icon={icLineType}
                            />
                            <Button
                                visualStyle={{ type: 'text' }}
                                size="small"
                                onClick={onToggleSize}
                                icon={isMaximized ? shrink : fullscreen}
                            />
                            <Button visualStyle={{ type: 'text' }} size="small" onClick={onClose} icon={closeIcon} />
                        </div>
                    )}
                </div>
            </ANTDHeader>
        </div>
    );
};
