import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import messages from './InsertionButton.messages';
import { useDispatch, useSelector } from 'react-redux';
import { pasteAction } from '../../../../../../actions/editor.actions';
import { TabsSelectors } from '../../../../../../selectors/tabs.selectors';
import { NodeId } from '../../../../../../serverapi/api';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { MxClipboard } from '../../../../../../mxgraph/mxgraph';
import { BPMMxGraph } from '../../../../../../mxgraph/bpmgraph';
import { instancesBPMMxGraphMap } from '../../../../../../mxgraph/bpm-mxgraph-instance-map';
import { noop } from 'lodash-es';
import { TButtonAction } from '../../../../../AdminTools/DropdownButton/DropdownButton.types';
import { DropdownButton } from '../../../../../AdminTools/DropdownButton/DropdownButton';
import icAdd from '@/resources/icons/MainMenuAdd-File.svg';

export const InsertionButton = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const activeTabId: NodeId = useSelector(TabsSelectors.getActiveTabId);

    const [isEmptyClipboard, setIsEmptyClipboard] = useState<boolean>(MxClipboard.isEmpty());

    const disabled: boolean = useSelector(EditorToolbarSelectors.getPasteBtnDisabled(isEmptyClipboard));
    const tooltip: string = useSelector(EditorToolbarSelectors.getPasteBtnTooltip(isEmptyClipboard));

    const activeGraph: BPMMxGraph | undefined = instancesBPMMxGraphMap.get(activeTabId);

    useEffect(() => {
        if (activeTabId && activeGraph) {
            const clipboardUpdate = () => {
                setIsEmptyClipboard(MxClipboard.isEmpty());
            };
            activeGraph.selectionModel.addListener('CLIPBOARD_UPDATE', clipboardUpdate);

            return () => {
                activeGraph.selectionModel.removeListener(clipboardUpdate);
            };
        }

        return noop;
    }, [activeTabId, activeGraph]);

    const actions: TButtonAction[] = [
        {
            title: intl.formatMessage(messages.insertAsInstance),
            onClick: () => dispatch(pasteAction(false)),
        },
        {
            title: intl.formatMessage(messages.insertAsObjectDefinition),
            onClick: () => dispatch(pasteAction(false)),
        },
    ];

    return (
        <DropdownButton
            spriteSymbol={icAdd}
            disabled={disabled}
            actions={actions}
            dropdownAlignment="left"
            dataTest="general-toolbar_insert-btn"
            tooltip={tooltip}
            size="small"
        />
    );
};
