// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonGroup__container__FNcFl{border-radius:4px;border:1px solid var(--ButtonGroupBorder);background-color:var(--ButtonGroupnBackground);display:-webkit-box;display:-ms-flexbox;display:flex;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.ButtonGroup__container__FNcFl.ButtonGroup__mainMenuBtn___kBJ8{border:none !important}.ButtonGroup__btnItem__zoM_w+.ButtonGroup__btnItem__zoM_w{margin-left:4px}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Toolbar/ButtonGroup/ButtonGroup.scss"],"names":[],"mappings":"AAAA,+BACI,iBAAA,CACA,yCAAA,CACA,8CAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CAGJ,+DACI,sBAAA,CAEJ,0DACI,eAAA","sourcesContent":[".container {\r\n    border-radius: 4px;\r\n    border: 1px solid var(--ButtonGroupBorder);\r\n    background-color: var(--ButtonGroupnBackground);\r\n    display: flex;\r\n    width: fit-content;\r\n}\r\n\r\n.container.mainMenuBtn {\r\n    border: none !important;\r\n}\r\n.btnItem + .btnItem {\r\n    margin-left: 4px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ButtonGroup__container__FNcFl",
	"mainMenuBtn": "ButtonGroup__mainMenuBtn___kBJ8",
	"btnItem": "ButtonGroup__btnItem__zoM_w"
};
export default ___CSS_LOADER_EXPORT___;
