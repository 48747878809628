import React from 'react';
import play from '@/resources/icons/Play.svg';
import { scriptNodeToExecute } from '../../../../../actions/entities/script.actions';
import { ToolbarBtn } from '../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import { useDispatch, useSelector } from 'react-redux';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { TWorkspaceTab } from '../../../../../models/tab.types';
import { IScriptNode } from '../../../../../models/bpm/bpm-model-impl.types';
import { useIntl } from 'react-intl';
import messages from '@/modules/MainMenu/messages/CommonToolbar.messages';

export const RunScriptButton = () => {
    const intl = useIntl();
    const dispatch = useDispatch();
    const activeTab: TWorkspaceTab | undefined = useSelector(TabsSelectors.getActiveTab);
    const script: IScriptNode | undefined = activeTab?.content;

    const handleRunScript = () => {
        if (script) dispatch(scriptNodeToExecute({ script, existingParams: [] }));
    };

    return (
        <ToolbarBtn
            icon={play}
            size="small"
            dataTest={'script_run_toolbar_button'}
            onClick={handleRunScript}
            tooltip={intl.formatMessage(messages.toRunScript)}
        />
    );
};
