import React from 'react';
import theme from './MainMenu.scss';
import { DividerComponent } from '../../TipTapTextEditor/Toolbar/controls/Divider.component';
import { ButtonGroup } from '../ButtonGroup/ButtonGroup.component';
import { ShowHiddenBtn } from './editorBtns/ShowHiddenBtn/ShowHiddenBtn.component';
import { OpenFormatBtn } from './editorBtns/OpenFormatBtn/OpenFormatBtn.component';

type TMAinMenu = {
    mainMenuToolbar: JSX.Element[];
    mainMenuRef: React.MutableRefObject<HTMLTableCellElement | null>;
    countOfHiddenGroups: number;
    menuWidth: number;
    withFormatBtn: boolean;
};

export const MainMenuWrapper = (props: TMAinMenu) => {
    const { mainMenuToolbar, mainMenuRef, countOfHiddenGroups, menuWidth, withFormatBtn } = props;
    return (
        <div className={theme.mainMenu} ref={mainMenuRef}>
            <div className={theme.mainButtons}>
                {mainMenuToolbar.map((group, ind) => {
                    return (
                        <>
                            <div
                                className={
                                    ind + 1 > mainMenuToolbar.length - countOfHiddenGroups
                                        ? theme.hiiddenGroup
                                        : undefined
                                }
                            >
                                {group}
                            </div>
                            {ind !== mainMenuToolbar.length - 1 ? <DividerComponent /> : null}
                        </>
                    );
                })}
            </div>
            <div className={theme.formatButton}>
                {countOfHiddenGroups && mainMenuRef.current ? (
                    <ButtonGroup isMainMenuBtn>
                        <ShowHiddenBtn
                            hiddenGroups={mainMenuToolbar.slice(-countOfHiddenGroups)}
                            containerWidth={menuWidth}
                            mainMenuRef={mainMenuRef.current}
                        />
                    </ButtonGroup>
                ) : null}
                {withFormatBtn ? (
                    <ButtonGroup isMainMenuBtn>
                        <OpenFormatBtn />
                    </ButtonGroup>
                ) : null}
            </div>
        </div>
    );
};
