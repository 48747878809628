import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import { useIntl } from 'react-intl';
import messages from '../CommonToolbar.messages';
import icListIndentDescrease from 'icons/toolbar/controls/ic-indent-decrease.svg';
import icListIndentIncrease from 'icons/toolbar/controls/ic-indent-increase.svg';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';
import { ToolbarSelect } from '../../../Toolbar/ButtonGroup/ToolbarSelect';
import { StateObserver } from '../../common/sharedState.class';
import { TToolbarBtn } from '../../../Toolbar/ButtonGroup/toolbarBtns.types';

const getFocusedBlock = (editor: Editor) => editor.getAttributes('paragraph').indent;

const getChangedBlock = (editor: Editor, action: string) => {
    switch (action) {
        case 'indent':
            return editor.chain().focus().indent().run();
        case 'outdent':
            return editor.chain().focus().outdent().run();
        default:
            return false;
    }
};

const indentMap = {
    indent: {
        action: 'indent',
        tooltip: messages.indent,
        icon: icListIndentIncrease,
        dataTest: 'wiki-toolbar-group_font-indent-button_increase',
    },
    outdent: {
        action: 'outdent',
        tooltip: messages.outdent,
        icon: icListIndentDescrease,
        dataTest: 'wiki-toolbar-group_font-indent-button_descrease',
    },
};

export const IndentComponent = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);

    const { setCurrentState } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    const onChange = (activeActionId: string) => () => {
        setCurrentState(activeActionId);
    };
    const indentTextButtons: React.ReactElement<TToolbarBtn, typeof ToolbarBtn>[] = Object.keys(indentMap).map(
        (key) => {
            return (
                <ToolbarBtn
                    icon={indentMap[key].icon}
                    tooltip={intl.formatMessage(indentMap[key].tooltip)}
                    onClick={onChange(indentMap[key].action)}
                    dataTest={indentMap[key].dataTest}
                />
            );
        },
    );

    return (
        <ToolbarSelect
            icon={indentMap.indent.icon}
            dataTest="wiki-toolbar-group_font-indent-button_popover"
            tooltip={intl.formatMessage(messages.textIndentation)}
            options={indentTextButtons}
            size="small"
        />
    );
};
