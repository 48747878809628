import React from 'react';
import { SpaceAction, SpaceActionBtns } from '../../../../../../models/insertionSpace';
import messages from './InsertSpaceSelect.messages';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { processSpaceAction } from '../../../../../../actions/editor.actions';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import { ToolbarSelect } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarSelect';

export const InsertSpaceSelect = () => {
    const dispatch = useDispatch();
    const intl = useIntl();
    const tooltip: string = useSelector(EditorToolbarSelectors.getInsertSpaceSelectTooltip);
    const disabled: boolean = useSelector(EditorToolbarSelectors.getInsertSpaceSelectDisabled);
    const gridButtons = Object.keys(SpaceAction).map((key) => {
        return (
            <ToolbarBtn
                icon={SpaceActionBtns[key]}
                tooltip={intl.formatMessage(messages[SpaceAction[key]])}
                onClick={() => dispatch(processSpaceAction(SpaceAction[key]))}
            />
        );
    });

    return (
        <ToolbarSelect
            options={gridButtons}
            icon={SpaceActionBtns.InsertHorizontal}
            size="small"
            disabled={disabled}
            tooltip={tooltip}
            dataTest="general-toolbar_insert-space-btn"
        />
    );
};
