import { createSelector } from 'reselect';
import { TabsSelectors } from './tabs.selectors';
import { UserProfileSelectors } from './userProfile.selectors';
import { LocalesService } from '../services/LocalesService';
import { TRootState } from '../reducers/root.reducer.types';
import { ToolbarButtonsBllService } from '../services/bll/ToolbarButtonsBllService';
import { TSelectedCellElements } from '../services/bll/ToolbarButtonsBllService.types';
import { getCopiedElements, getSelectedCellIds } from './editor.selectors';
import { getCellsInfo } from '../modules/UIKit/components/Toolbar/MainMenu/mainMenu.utils';
import { TCurrentUserProfile } from '../reducers/userProfile.reducer.types';
import {
    DiagramElement,
    EdgeDefinitionNode,
    EdgeInstance,
    MatrixLane,
    ModelAssignment,
    ModelNode,
    NodeId,
    ObjectInstance,
    ShapeInstance,
    Symbol,
} from '../serverapi/api';
import { getIsSequenceGraph, ModelTypes } from '../models/ModelTypes';
import { createCommonTooltipMessage } from '../modules/MainMenu/utils';
import { ModelSelectors } from './model.selectors';
import { CanvasSettings } from './canvas-settings.selectors';
import { EdgeDefinitionSelectors } from './edgeDefinition.selector';
import { ObjectDefinitionSelectors } from './objectDefinition.selectors';
import { ObjectDefinitionImpl, ObjectInstanceImpl } from '../models/bpm/bpm-model-impl';
import { BpmNodeFilterBase } from '../models/bpmNodeFilterBase';
import { isSystemStyle } from '../services/SymbolsService';
import { MxCell } from '../mxgraph/mxgraph';
import { ComplexSymbolManager } from '../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { ProfileBllService } from '../services/bll/ProfileBllService';
import { SymbolSelectors } from './symbol.selectors';
import { TreeSelectors } from './tree.selectors';
import { NotationHelper } from '../services/utils/NotationHelper';
import { TCellsInfo } from '../modules/UIKit/components/Toolbar/MainMenu/mainMenu.types';
import messages from '@/modules/MainMenu/messages/CommonToolbar.messages';
import { TWorkspaceTab } from '../models/tab.types';

export namespace EditorToolbarSelectors {
    export const getSelectedCellsInActiveGraph = createSelector<TRootState, NodeId, string[], TSelectedCellElements>(
        TabsSelectors.getActiveTabId,
        getSelectedCellIds,
        (activeTabId, selectedCellsIds) => {
            return ToolbarButtonsBllService.getRawSelectedCellsAndSelectedCellsWithoutLabelsInActiveGraph(
                activeTabId,
                selectedCellsIds,
            );
        },
    );

    export const getCellsInfoInActiveGraph = () => {
        return (state: TRootState): TCellsInfo => {
            const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
            const userProfile: TCurrentUserProfile | undefined =
                UserProfileSelectors.selectUserProfileByNodeId(activeTabId)(state);
            const { rawSelectedCells }: TSelectedCellElements = getSelectedCellsInActiveGraph(state);

            return getCellsInfo(rawSelectedCells, userProfile);
        };
    };

    export const getEdiitorModeDisabled = () => {
        return (state: TRootState): boolean => {
            const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
            const modelTypeId: string | undefined = TabsSelectors.getActiveTabModelTypeid(state);
            const editModeBtnDisabled: boolean = TabsSelectors.getActiveContentUserEditDisabled(state);
            const activeWorkspaceTab: TWorkspaceTab | undefined = TabsSelectors.getActiveTab(state);
            const isLoading: boolean = activeWorkspaceTab?.type === 'ContentLoadingPageTab';

            return (
                !UserProfileSelectors.isModelUpdatable(activeTabId, modelTypeId)(state) ||
                editModeBtnDisabled ||
                isLoading
            );
        };
    };

    export const getEdiitorModeTooltip = createSelector<TRootState, boolean, boolean, string>(
        getEdiitorModeDisabled(),
        TabsSelectors.checkIsReadModeInActiveTab,
        (disabled, isReadMode) => {
            const intl = LocalesService.useIntl();

            return disabled
                ? intl.formatMessage(messages.unlockDisabled)
                : isReadMode
                ? intl.formatMessage(messages.toEditMode)
                : intl.formatMessage(messages.toReadMode);
        },
    );

    export const getPasteBtnDisabled = (isEmptyClipboard: boolean) =>
        createSelector<TRootState, boolean, DiagramElement[] | MatrixLane[], string | undefined, boolean>(
            TabsSelectors.checkIsReadModeInActiveTab,
            getCopiedElements,
            TabsSelectors.getActiveTabModelTypeid,
            (isReadMode, copiedElements, modelTypeId) => {
                const isWhiteBoard: boolean = modelTypeId === ModelTypes.MIND_MAP;
                const hasCopiedElements: boolean = copiedElements.length > 0 || (isWhiteBoard && !isEmptyClipboard);
                const disabled: boolean = isReadMode || !hasCopiedElements;

                return disabled;
            },
        );

    export const getPasteBtnTooltip = (isEmptyClipboard: boolean) =>
        createSelector<TRootState, string | undefined, boolean, boolean, string>(
            TabsSelectors.getActiveTabModelTypeid,
            TabsSelectors.checkIsReadModeInActiveTab,
            getPasteBtnDisabled(isEmptyClipboard),
            (modelTypeId, isReadMode, disabled) => {
                const intl = LocalesService.useIntl();
                const isSequenceGraph: boolean = getIsSequenceGraph(modelTypeId);

                const message: string = intl.formatMessage(messages.paste);
                const disabledMessage: string = intl.formatMessage(messages.clipboardIsEmpty);

                if (isSequenceGraph && disabled)
                    return `${message} ${intl.formatMessage(messages.sequenceActionDisabled)}`;

                if (isReadMode) return intl.formatMessage(messages.selectElementInEditorMode);

                return createCommonTooltipMessage(message, disabled, disabledMessage);
            },
        );

    export const getCopyBtnDisabled = createSelector<TRootState, NodeId, TSelectedCellElements, boolean>(
        TabsSelectors.getActiveTabId,
        getSelectedCellsInActiveGraph,
        (activeTabId, { rawSelectedCells }) => {
            const hasCopyableCells: boolean = ToolbarButtonsBllService.hasCopyableCellsInActiveGraph(
                activeTabId,
                rawSelectedCells,
            );
            const disabled: boolean = !hasCopyableCells;

            return disabled;
        },
    );

    export const getCopyBtnTooltip = createSelector<TRootState, string | undefined, boolean, boolean, string>(
        TabsSelectors.getActiveTabModelTypeid,
        TabsSelectors.checkIsReadModeInActiveTab,
        getCopyBtnDisabled,
        (modelTypeId, isReadMode, disabled) => {
            const intl = LocalesService.useIntl();
            const isSequenceGraph: boolean = getIsSequenceGraph(modelTypeId);

            const message: string = intl.formatMessage(messages.copy);
            const disabledMessage: string = intl.formatMessage(messages.selectElementOnCanvas);

            if (isSequenceGraph && disabled) return `${message} ${intl.formatMessage(messages.sequenceActionDisabled)}`;

            if (isReadMode) return intl.formatMessage(messages.selectElementInEditorMode);

            return createCommonTooltipMessage(message, disabled, disabledMessage);
        },
    );

    export const getPrintBtnDisabled = () => {
        return (state: TRootState): boolean => {
            const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
            const model: ModelNode | undefined = ModelSelectors.byId(activeTabId)(state);
            const isPrintable: boolean = !!model?.printable;

            return !isPrintable;
        };
    };

    export const getPrintBtnTooltip = createSelector<TRootState, boolean, string>(getPrintBtnDisabled(), (disabled) => {
        const intl = LocalesService.useIntl();

        return intl.formatMessage(!disabled ? messages.print : messages.noAccessToPrint);
    });

    export const getCutBtnDisabled = () => {
        return (state: TRootState): boolean => {
            const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
            const isReadMode: boolean = TabsSelectors.checkIsReadModeInActiveTab(state);
            const { rawSelectedCells }: TSelectedCellElements = getSelectedCellsInActiveGraph(state);
            const hasCopyableCells: boolean = ToolbarButtonsBllService.hasCopyableCellsInActiveGraph(
                activeTabId,
                rawSelectedCells,
            );
            const { hasNonDeletableSelectedEdge } = getCellsInfoInActiveGraph()(state);
            const disabled: boolean = hasNonDeletableSelectedEdge || isReadMode || !hasCopyableCells;

            return disabled;
        };
    };

    export const getCutBtnTooltip = createSelector<TRootState, string | undefined, boolean, boolean, string>(
        TabsSelectors.getActiveTabModelTypeid,
        TabsSelectors.checkIsReadModeInActiveTab,
        getCutBtnDisabled(),
        (modelTypeId, isReadMode, disabled) => {
            const intl = LocalesService.useIntl();
            const isSequenceGraph: boolean = getIsSequenceGraph(modelTypeId);

            const message: string = intl.formatMessage(messages.cut);
            const disabledMessage: string = intl.formatMessage(messages.selectElementOnCanvas);

            if (isSequenceGraph && disabled) return `${message} ${intl.formatMessage(messages.sequenceActionDisabled)}`;

            if (isReadMode) return intl.formatMessage(messages.selectElementInEditorMode);

            return createCommonTooltipMessage(message, disabled, disabledMessage);
        },
    );

    export const getDeleteBtnDisabled = createSelector<TRootState, boolean, TCellsInfo, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        getCellsInfoInActiveGraph(),
        (isReadMode, cellInfo) => {
            const { hasNonDeletableSelectedEdge, selectedCellsCount, hasNonEditableSelectedObject } = cellInfo;
            const hasOneCellSelected: boolean = selectedCellsCount === 1;
            const hasMoreThanOneCellsSelected: boolean = selectedCellsCount > 1;
            const disabled: boolean =
                !(hasOneCellSelected || hasMoreThanOneCellsSelected) ||
                hasNonDeletableSelectedEdge ||
                isReadMode ||
                hasNonEditableSelectedObject;

            return disabled;
        },
    );

    export const getDeleteBtnTooltip = createSelector<TRootState, string | undefined, boolean, boolean, string>(
        TabsSelectors.getActiveTabModelTypeid,
        TabsSelectors.checkIsReadModeInActiveTab,
        getDeleteBtnDisabled,
        (modelTypeId, isReadMode, disabled) => {
            const intl = LocalesService.useIntl();
            const isSequenceGraph: boolean = getIsSequenceGraph(modelTypeId);

            const message: string = intl.formatMessage(messages.toBasket);
            const disabledMessage: string = intl.formatMessage(messages.selectElementOnCanvas);

            if (isSequenceGraph && disabled) return `${message} ${intl.formatMessage(messages.sequenceActionDisabled)}`;

            if (isReadMode) return intl.formatMessage(messages.selectElementInEditorMode);

            return createCommonTooltipMessage(message, disabled, disabledMessage);
        },
    );

    export const getUpdateBtnDisabled = createSelector<TRootState, boolean, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            return !isReadMode;
        },
    );

    export const getUpdateBtnTooltip = createSelector<TRootState, boolean, string>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            const intl = LocalesService.useIntl();

            return isReadMode
                ? intl.formatMessage(messages.update)
                : intl.formatMessage(messages.onlyInReadModeAvailable);
        },
    );

    export const getSearchBtnDisabled = createSelector<TRootState, boolean, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            return !isReadMode;
        },
    );

    export const getSearchBtnTooltip = createSelector<TRootState, boolean, string>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            const intl = LocalesService.useIntl();

            return isReadMode
                ? intl.formatMessage(messages.search)
                : intl.formatMessage(messages.onlyInReadModeAvailable);
        },
    );

    export const getGridBtnTooltip = createSelector(CanvasSettings.gridType, (gridType) => {
        const intl = LocalesService.useIntl();

        return gridType ? intl.formatMessage(messages[gridType]) : intl.formatMessage(messages.grid);
    });

    export const getInsertSpaceSelectDisabled = createSelector<TRootState, boolean, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            return isReadMode;
        },
    );

    export const getInsertSpaceSelectTooltip = createSelector<TRootState, boolean, string>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            const intl = LocalesService.useIntl();

            return isReadMode
                ? intl.formatMessage(messages.onlyInEditModeAvailable)
                : intl.formatMessage(messages.space);
        },
    );

    export const getAlignSelectDisabled = createSelector<TRootState, boolean, string | undefined, TCellsInfo, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        TabsSelectors.getActiveTabModelTypeid,
        getCellsInfoInActiveGraph(),
        (isReadMode, modelTypeId, { selectedObjectsCount }) => {
            const minTwoObjectsSelected: boolean = selectedObjectsCount >= 2;
            const isSequenceGraph: boolean = getIsSequenceGraph(modelTypeId);
            const alignBtnDisabled: boolean = isReadMode || !minTwoObjectsSelected || isSequenceGraph;

            return alignBtnDisabled;
        },
    );

    export const getAlignSelectTooltip = createSelector<TRootState, boolean, string | undefined, boolean, string>(
        getAlignSelectDisabled,
        TabsSelectors.getActiveTabModelTypeid,
        TabsSelectors.checkIsReadModeInActiveTab,
        (disabled, modelTypeId, isReadMode) => {
            const intl = LocalesService.useIntl();
            const isSequenceGraph: boolean = getIsSequenceGraph(modelTypeId);
            if (!disabled) return intl.formatMessage(messages.ToAlign);
            if (isSequenceGraph) return intl.formatMessage(messages.sequenceAlignDisabled);

            return isReadMode
                ? intl.formatMessage(messages.onlyInEditModeAvailable)
                : intl.formatMessage(messages.selectMinTwoElementsOnCanvas);
        },
    );

    export const getDistributerBtnDisabled = createSelector<TRootState, TCellsInfo, boolean>(
        getCellsInfoInActiveGraph(),
        ({ selectedObjectsCount }) => {
            const minThreeObjectsSelected: boolean = selectedObjectsCount >= 3;
            const disabled: boolean = !minThreeObjectsSelected;

            return disabled;
        },
    );

    export const getMoveLayerSelectDisabled = createSelector<TRootState, boolean, TCellsInfo, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        getCellsInfoInActiveGraph(),
        (isReadMode, cellsInFo) => {
            const { hasNonEditableSelectedEdge, selectedCellsCount } = cellsInFo;
            const hasOneOrMoreCellsSelected: boolean = selectedCellsCount > 0;
            const moveBtnDisabled: boolean = isReadMode || hasNonEditableSelectedEdge || !hasOneOrMoreCellsSelected;

            return moveBtnDisabled;
        },
    );

    export const getMoveLayerSelectTooltip = createSelector<TRootState, boolean, boolean, string>(
        getMoveLayerSelectDisabled,
        TabsSelectors.checkIsReadModeInActiveTab,
        (disabled, isReadMode) => {
            const intl = LocalesService.useIntl();
            if (!disabled) return intl.formatMessage(messages.toMove);

            return isReadMode
                ? intl.formatMessage(messages.selectElementInEditMode)
                : intl.formatMessage(messages.selectElementOnCanvas);
        },
    );

    export const getPropertiesBtnDisabled = createSelector<TRootState, TCellsInfo, string | undefined, boolean>(
        getCellsInfoInActiveGraph(),
        TabsSelectors.getActiveTabModelTypeid,
        (cellsInfo, modelTypeId) => {
            const { selectedCellsCount, hasSelectedShapesWithoutImage } = cellsInfo;
            const isWhiteBoard: boolean = modelTypeId === ModelTypes.MIND_MAP;
            const hasMoreThanOneCellsSelected: boolean = selectedCellsCount > 1;
            const hasOneCellSelected: boolean = selectedCellsCount === 1;
            const propertyBtnDisabled: boolean =
                hasSelectedShapesWithoutImage || hasMoreThanOneCellsSelected || (isWhiteBoard && hasOneCellSelected);

            return propertyBtnDisabled;
        },
    );

    export const getPropertiesBtnTooltip = () => {
        return (state: TRootState): string => {
            const intl = LocalesService.useIntl();

            return intl.formatMessage(messages.textProperty);
        };
    };

    export const getDecompositionBtnDisabled = () => {
        return (state: TRootState): boolean => {
            const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
            const { rawSelectedCells }: TSelectedCellElements = getSelectedCellsInActiveGraph(state);
            const { selectedCellsCount, firstSelectedCell, selectedEdgesCount } = getCellsInfoInActiveGraph()(state);
            const modelTypeId: string | undefined = TabsSelectors.getActiveTabModelTypeid(state);

            const isWhiteBoard: boolean = modelTypeId === ModelTypes.MIND_MAP;
            const hasOneCellSelected: boolean = selectedCellsCount === 1;
            const selectedCellValue: DiagramElement = firstSelectedCell?.getValue();
            const { serverId, repositoryId } = activeTabId;

            const edgeDefinition: EdgeDefinitionNode | undefined = EdgeDefinitionSelectors.byId({
                id: (selectedCellValue as EdgeInstance | undefined)?.edgeDefinitionId || '',
                serverId,
                repositoryId,
            })(state);

            const onlyEdgesSelected: boolean = selectedEdgesCount === rawSelectedCells.length;
            const oneEdgeOrObjectSelected: boolean =
                hasOneCellSelected && (selectedCellValue?.type === 'object' || selectedCellValue?.type === 'edge');

            const decompositionBtnDisabled: boolean =
                !(oneEdgeOrObjectSelected && !isWhiteBoard) || (!edgeDefinition && onlyEdgesSelected);

            return decompositionBtnDisabled;
        };
    };

    export const getDecompositionBtnTooltip = createSelector<
        TRootState,
        boolean,
        boolean,
        TSelectedCellElements,
        TCellsInfo,
        string | undefined,
        string
    >(
        getDecompositionBtnDisabled(),
        TabsSelectors.checkIsReadModeInActiveTab,
        getSelectedCellsInActiveGraph,
        getCellsInfoInActiveGraph(),
        TabsSelectors.getActiveTabModelTypeid,
        (disabled, isReadMode, { selectedCellsWithoutLabels }, cellsInfo, modelTypeId) => {
            const intl = LocalesService.useIntl();
            const { selectedCellsCount, firstSelectedCell } = cellsInfo;
            const hasOneCellSelected: boolean = selectedCellsCount === 1;
            const selectedCellValue: DiagramElement = firstSelectedCell?.getValue();
            const oneEdgeOrObjectSelected: boolean =
                hasOneCellSelected && (selectedCellValue?.type === 'object' || selectedCellValue?.type === 'edge');
            const isSequenceGraph: boolean = getIsSequenceGraph(modelTypeId);

            const message: string = oneEdgeOrObjectSelected
                ? intl.formatMessage(messages.textDecomposition)
                : intl.formatMessage(messages.selectElementOnCanvas);
            const disabledMessage: string =
                !oneEdgeOrObjectSelected && selectedCellsWithoutLabels.length === 1
                    ? intl.formatMessage(messages.onlyObjectAndEdgeAvailable)
                    : intl.formatMessage(messages.selectElementOnCanvas);

            if (isSequenceGraph && disabled) return `${message} ${intl.formatMessage(messages.sequenceActionDisabled)}`;

            if (isReadMode) return intl.formatMessage(messages.selectElementInEditorMode);

            return createCommonTooltipMessage(message, disabled, disabledMessage);
        },
    );

    export const getPickOutSelectTooltip = () => {
        return (state: TRootState): string => {
            const intl = LocalesService.useIntl();

            return intl.formatMessage(messages.PickDefault);
        };
    };

    export const getMoveToSelectDisabled = () => {
        return (state: TRootState): boolean => {
            const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
            const { selectedCellsCount, firstSelectedCell, hasNonEditableSelectedObject } =
                getCellsInfoInActiveGraph()(state);
            const hasOneCellSelected: boolean = selectedCellsCount === 1;
            const selectedCellValue: DiagramElement = firstSelectedCell?.getValue();
            const hasOneObjSelected = hasOneCellSelected && selectedCellValue?.type === 'object';
            const hasOneEdgeWithDefinition: boolean =
                hasOneCellSelected &&
                selectedCellValue?.type === 'edge' &&
                !!(selectedCellValue as EdgeInstance).edgeDefinitionId;
            const hasOneShapeWithImageId: boolean =
                hasOneCellSelected &&
                selectedCellValue?.type === 'shape' &&
                !!(selectedCellValue as ShapeInstance).imageId;
            const { serverId, repositoryId } = activeTabId;
            const objectDefinition: ObjectDefinitionImpl | undefined = ObjectDefinitionSelectors.byId({
                id: (selectedCellValue as ObjectInstance | undefined)?.objectDefinitionId || '',
                serverId,
                repositoryId,
            })(state);

            const edgeDefinition: EdgeDefinitionNode | undefined = EdgeDefinitionSelectors.byId({
                id: (selectedCellValue as EdgeInstance | undefined)?.edgeDefinitionId || '',
                serverId,
                repositoryId,
            })(state);

            const showMoveToTreeBtn: boolean =
                hasOneObjSelected || selectedCellsCount === 0 || hasOneShapeWithImageId || hasOneEdgeWithDefinition;
            const modelAssignments: ModelAssignment[] =
                objectDefinition?.modelAssignments || edgeDefinition?.modelAssignments || [];
            const hasModelAssignments: boolean = modelAssignments.length > 0;
            const hasTransition: boolean = showMoveToTreeBtn || hasModelAssignments;
            const goToBtnDisabled: boolean = !hasTransition || hasNonEditableSelectedObject;

            return goToBtnDisabled;
        };
    };

    export const getMoveToSelectTooltip = () => {
        return (state: TRootState): string => {
            const intl = LocalesService.useIntl();

            return intl.formatMessage(messages.goTo);
        };
    };

    export const getGoToDecompositionBtnDisabled = () => {
        return (state: TRootState): boolean => {
            const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
            const { firstSelectedCell } = getCellsInfoInActiveGraph()(state);
            const selectedCellValue: DiagramElement = firstSelectedCell?.getValue();
            const { serverId, repositoryId } = activeTabId;
            const objectDefinition: ObjectDefinitionImpl | undefined = ObjectDefinitionSelectors.byId({
                id: (selectedCellValue as ObjectInstance | undefined)?.objectDefinitionId || '',
                serverId,
                repositoryId,
            })(state);

            const edgeDefinition: EdgeDefinitionNode | undefined = EdgeDefinitionSelectors.byId({
                id: (selectedCellValue as EdgeInstance | undefined)?.edgeDefinitionId || '',
                serverId,
                repositoryId,
            })(state);

            const modelAssignments: ModelAssignment[] =
                objectDefinition?.modelAssignments || edgeDefinition?.modelAssignments || [];
            const hasModelAssignments: boolean = modelAssignments.length > 0;
            const disabled: boolean = !hasModelAssignments;

            return disabled;
        };
    };

    export const getGoToObjectInTreeBtnDisabled = createSelector<TRootState, TCellsInfo, boolean>(
        getCellsInfoInActiveGraph(),
        (cellsInfo) => {
            const { selectedCellsCount, firstSelectedCell } = cellsInfo;
            const hasOneCellSelected: boolean = selectedCellsCount === 1;
            const selectedCellValue: DiagramElement = firstSelectedCell?.getValue();
            const hasOneObjSelected = hasOneCellSelected && selectedCellValue?.type === 'object';
            const hasOneEdgeWithDefinition: boolean =
                hasOneCellSelected &&
                selectedCellValue?.type === 'edge' &&
                !!(selectedCellValue as EdgeInstance).edgeDefinitionId;
            const hasOneShapeWithImageId: boolean =
                hasOneCellSelected &&
                selectedCellValue?.type === 'shape' &&
                !!(selectedCellValue as ShapeInstance).imageId;

            const showMoveToTreeBtn: boolean =
                hasOneObjSelected || selectedCellsCount === 0 || hasOneShapeWithImageId || hasOneEdgeWithDefinition;

            const disabled: boolean = !showMoveToTreeBtn;

            return disabled;
        },
    );

    export const getFiltrationBtnDisabled = () =>
        createSelector<TRootState, boolean, string | undefined, boolean>(
            TabsSelectors.checkIsReadModeInActiveTab,
            TabsSelectors.getActiveTabModelTypeid,
            (isReadMode, modelTypeId) => {
                const isWhiteBoard: boolean = modelTypeId === ModelTypes.MIND_MAP;
                const disabled: boolean = !isReadMode || isWhiteBoard;

                return disabled;
            },
        );

    export const getFiltrationBtnTooltip = createSelector<TRootState, boolean, BpmNodeFilterBase | undefined, string>(
        getFiltrationBtnDisabled(),
        TabsSelectors.getActiveTabNodeFilterBase,
        (disabled, nodeFilterBase) => {
            const intl = LocalesService.useIntl();

            if (disabled) return intl.formatMessage(messages.onlyInReadModeAvailable);

            const activeNodeFilter: BpmNodeFilterBase = nodeFilterBase || BpmNodeFilterBase.Off;

            return activeNodeFilter && activeNodeFilter !== BpmNodeFilterBase.Off
                ? intl.formatMessage(messages[`NodeFilter${BpmNodeFilterBase[activeNodeFilter]}`])
                : intl.formatMessage(messages.nodeFilterDisabled);
        },
    );

    export const getFilterInputBtnDisabled = createSelector<
        TRootState,
        boolean,
        BpmNodeFilterBase | undefined,
        boolean
    >(
        TabsSelectors.checkIsReadModeInActiveTab,
        TabsSelectors.getActiveTabNodeFilterBase,
        (isReadMode, nodeFilterBase) => {
            const disabled: boolean = !isReadMode || !nodeFilterBase || nodeFilterBase === BpmNodeFilterBase.Off;

            return disabled;
        },
    );

    export const getFilterInputBtnTooltip = createSelector<TRootState, boolean, string>(
        getFilterInputBtnDisabled,
        (disabled) => {
            const intl = LocalesService.useIntl();
            const tooltip: string = disabled
                ? intl.formatMessage(messages.enableFiltering)
                : intl.formatMessage(messages.nodeFilterInput);

            return tooltip;
        },
    );

    export const getFilterOutputBtnDisabled = createSelector<
        TRootState,
        boolean,
        BpmNodeFilterBase | undefined,
        boolean
    >(
        TabsSelectors.checkIsReadModeInActiveTab,
        TabsSelectors.getActiveTabNodeFilterBase,
        (isReadMode, nodeFilterBase) => {
            const disabled: boolean = !isReadMode || !nodeFilterBase || nodeFilterBase === BpmNodeFilterBase.Off;

            return disabled;
        },
    );

    export const getFilterOutputBtnTooltip = createSelector<TRootState, boolean, string>(
        getFilterInputBtnDisabled,
        (disabled) => {
            const intl = LocalesService.useIntl();
            const tooltip: string = disabled
                ? intl.formatMessage(messages.enableFiltering)
                : intl.formatMessage(messages.nodeFilterOutput);

            return tooltip;
        },
    );

    export const getAddShapeDisabled = createSelector<TRootState, boolean, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            const disabled: boolean = isReadMode;

            return disabled;
        },
    );

    export const getAddImageDisabled = createSelector<TRootState, boolean, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            const disabled: boolean = isReadMode;

            return disabled;
        },
    );

    export const getaddImageBtnTooltip = createSelector<TRootState, boolean, string>(
        getAddShapeDisabled,
        (disabled) => {
            const intl = LocalesService.useIntl();
            const tooltip: string = disabled
                ? intl.formatMessage(messages.onlyInEditModeAvailable)
                : intl.formatMessage(messages.labelDownloadImage);

            return tooltip;
        },
    );

    export const getaddTextBtnTooltip = createSelector<TRootState, boolean, string>(getAddShapeDisabled, (disabled) => {
        const intl = LocalesService.useIntl();
        const tooltip: string = disabled
            ? intl.formatMessage(messages.onlyInEditModeAvailable)
            : intl.formatMessage(messages.labelPasteText);

        return tooltip;
    });

    export const getaddShapeBtnTooltip = createSelector<TRootState, boolean, string>(
        getAddShapeDisabled,
        (disabled) => {
            const intl = LocalesService.useIntl();
            const tooltip: string = disabled
                ? intl.formatMessage(messages.onlyInEditModeAvailable)
                : intl.formatMessage(messages.shapes);

            return tooltip;
        },
    );

    const getSelectedCellsEditable = (cells: MxCell[], userProfile: TCurrentUserProfile | undefined): boolean => {
        return cells.reduce(
            (acc, item) =>
                item?.getValue()?.type !== 'object'
                    ? acc
                    : acc && !!userProfile && ProfileBllService.isSymbolEditable(userProfile, item.value.idSymbol),
            true,
        );
    };

    const getObjectWithoutSymbol = (cells: MxCell[], symbols: Symbol[]): boolean => {
        return cells.reduce(
            (acc, item) =>
                item?.value?.type !== 'object'
                    ? acc
                    : !!symbols.find((symbol) => symbol.id === new ObjectInstanceImpl(item.getValue()).symbolId) || acc,
            true,
        );
    };

    export const getSampleFormatBtnDisabled = () => {
        return (state: TRootState): boolean => {
            const isReadMode: boolean = TabsSelectors.checkIsReadModeInActiveTab(state);
            const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
            const userProfile: TCurrentUserProfile | undefined =
                UserProfileSelectors.selectUserProfileByNodeId(activeTabId)(state);
            const presetId: string = TreeSelectors.presetById(activeTabId)(state);
            const symbols: Symbol[] = SymbolSelectors.byServerIdPresetId(activeTabId.serverId, presetId)(state);
            const filterSelectedCells = (selectedCells: MxCell[]) =>
                selectedCells.filter(
                    (cell) => !isSystemStyle(cell.style) && ComplexSymbolManager.isCellStyleEditable(cell),
                );
            const { rawSelectedCells }: TSelectedCellElements = getSelectedCellsInActiveGraph(state);
            const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);
            const hasOneOrMoreElSelected: boolean = selectedCells.length >= 1;
            const isSelectedCellsEditable: boolean = getSelectedCellsEditable(selectedCells, userProfile);
            const objectWithoutSymbol: boolean = getObjectWithoutSymbol(selectedCells, symbols);
            const fixedStyleComplexCells: MxCell[] = rawSelectedCells.filter(
                (cell) =>
                    ComplexSymbolManager.isComplexSymbolCell(cell) && !ComplexSymbolManager.isCellStyleEditable(cell),
            );
            const poolOrSwimlaneCellSelected: boolean = fixedStyleComplexCells.length >= 1;
            const hasSelectedOnlyImages: boolean = selectedCells.every((cell) => cell.getValue().imageId);
            const modelTypeId: string | undefined = TabsSelectors.getActiveTabModelTypeid(state);
            const isSequenceGraph: boolean = modelTypeId === ModelTypes.SEQUENCE_DIAGRAM;
            const formatBtnDisabled: boolean =
                isReadMode ||
                !hasOneOrMoreElSelected ||
                !isSelectedCellsEditable ||
                !objectWithoutSymbol ||
                poolOrSwimlaneCellSelected ||
                hasSelectedOnlyImages ||
                isSequenceGraph;

            return formatBtnDisabled;
        };
    };

    export const getSampleFormatBtnTooltip = () => {
        return (state: TRootState): string => {
            const intl = LocalesService.useIntl();
            const formatBtnDisabled: boolean = getSampleFormatBtnDisabled()(state);
            const filterSelectedCells = (selectedCells: MxCell[]) =>
                selectedCells.filter(
                    (cell) => !isSystemStyle(cell.style) && ComplexSymbolManager.isCellStyleEditable(cell),
                );
            const { rawSelectedCells }: TSelectedCellElements = getSelectedCellsInActiveGraph(state);
            const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);
            const fixedStyleComplexCells: MxCell[] = rawSelectedCells.filter(
                (cell) =>
                    ComplexSymbolManager.isComplexSymbolCell(cell) && !ComplexSymbolManager.isCellStyleEditable(cell),
            );
            const poolOrSwimlaneCellSelected: boolean = fixedStyleComplexCells.length >= 1;
            const hasSelectedOnlyImages: boolean = selectedCells.every((cell) => cell.getValue().imageId);
            const message: string = intl.formatMessage(messages.format);
            const disabledMessage: string =
                poolOrSwimlaneCellSelected || hasSelectedOnlyImages
                    ? intl.formatMessage(messages.poolOrSwimlaneOrImageUnavailable)
                    : intl.formatMessage(messages.selectElementOnCanvas);
            const tooltip = createCommonTooltipMessage(message, formatBtnDisabled, disabledMessage);

            return tooltip;
        };
    };
    export const getClearFormatBtnDisabled = () => {
        return (state: TRootState): boolean => {
            const isReadMode: boolean = TabsSelectors.checkIsReadModeInActiveTab(state);
            const activeTabId: NodeId = TabsSelectors.getActiveTabId(state);
            const userProfile: TCurrentUserProfile | undefined =
                UserProfileSelectors.selectUserProfileByNodeId(activeTabId)(state);
            const presetId: string = TreeSelectors.presetById(activeTabId)(state);
            const symbols: Symbol[] = SymbolSelectors.byServerIdPresetId(activeTabId.serverId, presetId)(state);
            const filterSelectedCells = (selectedCells: MxCell[]) =>
                selectedCells.filter(
                    (cell) => !isSystemStyle(cell.style) && ComplexSymbolManager.isCellStyleEditable(cell),
                );
            const { rawSelectedCells }: TSelectedCellElements = getSelectedCellsInActiveGraph(state);
            const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);
            const hasOneOrMoreElSelected: boolean = selectedCells.length >= 1;
            const isSelectedCellsEditable: boolean = getSelectedCellsEditable(selectedCells, userProfile);
            const objectWithoutSymbol: boolean = getObjectWithoutSymbol(selectedCells, symbols);
            const fixedStyleComplexCells: MxCell[] = rawSelectedCells.filter(
                (cell) =>
                    ComplexSymbolManager.isComplexSymbolCell(cell) && !ComplexSymbolManager.isCellStyleEditable(cell),
            );
            const poolOrSwimlaneCellSelected: boolean = fixedStyleComplexCells.length >= 1;
            const hasSelectedOnlyImages: boolean = selectedCells.every((cell) => cell.getValue().imageId);
            const modelTypeId: string | undefined = TabsSelectors.getActiveTabModelTypeid(state);
            const isSequenceGraph: boolean = modelTypeId === ModelTypes.SEQUENCE_DIAGRAM;
            const isEntityEditable: boolean = NotationHelper.isEntityEditable(activeTabId, presetId, selectedCells);
            const clearFormatBtnDisabled: boolean =
                isReadMode ||
                !hasOneOrMoreElSelected ||
                !isEntityEditable ||
                !isSelectedCellsEditable ||
                !objectWithoutSymbol ||
                poolOrSwimlaneCellSelected ||
                hasSelectedOnlyImages ||
                isSequenceGraph;

            return clearFormatBtnDisabled;
        };
    };

    export const getClearFormatBtnTooltip = () => {
        return (state: TRootState): string => {
            const intl = LocalesService.useIntl();
            const formatBtnDisabled: boolean = getSampleFormatBtnDisabled()(state);
            const filterSelectedCells = (selectedCells: MxCell[]) =>
                selectedCells.filter(
                    (cell) => !isSystemStyle(cell.style) && ComplexSymbolManager.isCellStyleEditable(cell),
                );
            const { rawSelectedCells }: TSelectedCellElements = getSelectedCellsInActiveGraph(state);
            const selectedCells: MxCell[] = filterSelectedCells(rawSelectedCells);
            const fixedStyleComplexCells: MxCell[] = rawSelectedCells.filter(
                (cell) =>
                    ComplexSymbolManager.isComplexSymbolCell(cell) && !ComplexSymbolManager.isCellStyleEditable(cell),
            );
            const poolOrSwimlaneCellSelected: boolean = fixedStyleComplexCells.length >= 1;
            const hasSelectedOnlyImages: boolean = selectedCells.every((cell) => cell.getValue().imageId);
            const message: string = intl.formatMessage(messages.clearFormat);
            const disabledMessage: string =
                poolOrSwimlaneCellSelected || hasSelectedOnlyImages
                    ? intl.formatMessage(messages.poolOrSwimlaneOrImageUnavailable)
                    : intl.formatMessage(messages.selectElementOnCanvas);
            const tooltip = createCommonTooltipMessage(message, formatBtnDisabled, disabledMessage);

            return tooltip;
        };
    };

    export const getOpenFormatBtnDisabled = createSelector<TRootState, boolean, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            return !isReadMode;
        },
    );

    export const getOpenFormatBtnTooltip = createSelector<TRootState, boolean, string>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            const intl = LocalesService.useIntl();

            return isReadMode
                ? intl.formatMessage(messages.onlyInEditModeAvailable)
                : intl.formatMessage(messages.openFormatPanel);
        },
    );
    export const getSaveButtonDisabled = createSelector<TRootState, boolean, boolean>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            return isReadMode;
        },
    );

    export const getSaveButtonBtnTooltip = createSelector<TRootState, boolean, string>(
        TabsSelectors.checkIsReadModeInActiveTab,
        (isReadMode) => {
            const intl = LocalesService.useIntl();

            return isReadMode
                ? intl.formatMessage(messages.onlyInEditModeAvailable)
                : intl.formatMessage(messages.save);
        },
    );
}
