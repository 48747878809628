import React from 'react';
import { Button } from '../../../Button/Button.component';
import addBtnIcon from '@/resources/icons/icAdd.svg';
import theme from './AddNewValueBtn.scss';
import { openDialog } from '../../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../../DialogRoot/DialogRoot.constants';
import { getStore } from '../../../../../../store';
import { Rule } from 'antd/es/form';

type TAddNewValueBtn = { onAddClicked: (value: string) => void; title: string; validationRules: Rule[] | undefined };

export const AddNewValueBtn = (props: TAddNewValueBtn) => {
    const { onAddClicked, title, validationRules } = props;
    const handleClick = () => {
        getStore().dispatch(
            openDialog(DialogType.ADD_NEW_VALUE_DIALOG, {
                title: title,
                handleOk: onAddClicked,
                validationRules: validationRules,
            }),
        );
    };

    return (
        <div className={theme.btnContainer}>
            <Button dataTest={'user-managment_add-user-button'} onClick={handleClick} icon={addBtnIcon} size="small" />
        </div>
    );
};
