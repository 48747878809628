import { MxCell } from '../../../../../mxgraph';
import { ComplexSymbolManager } from '../../../../../mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { TCurrentUserProfile } from '../../../../../reducers/userProfile.reducer.types';
import { DiagramElement } from '../../../../../serverapi/api';
import { ProfileBllService } from '../../../../../services/bll/ProfileBllService';
import { TCellsInfo } from './mainMenu.types';

export const getCellsInfo = (selectedCells: MxCell[], userProfile: TCurrentUserProfile | undefined): TCellsInfo => {
    let firstSelectedCell;
    let selectedCellsCount = 0;
    let selectedObjectsCount = 0;
    let selectedEdgesCount = 0;

    let hasNonEditableSelectedObject: boolean = false;
    let hasNonEditableSelectedEdge: boolean = false;
    let hasNonDeletableSelectedEdge: boolean = false;
    let hasSelectedShapesWithoutImage: boolean = false;

    selectedCells.forEach((cell) => {
        const cellValue: DiagramElement | undefined = cell.getValue();

        if (ComplexSymbolManager.isCellStyleEditable(cell)) {
            firstSelectedCell = !firstSelectedCell ? cell : firstSelectedCell;
            selectedCellsCount = selectedCellsCount + 1;
            selectedObjectsCount = cellValue?.type === 'object' ? selectedObjectsCount + 1 : selectedObjectsCount;
            selectedEdgesCount = cellValue?.type === 'edge' ? selectedEdgesCount + 1 : selectedEdgesCount;
        }

        if (cellValue?.type === 'object') {
            hasNonEditableSelectedObject =
                hasNonEditableSelectedObject ||
                (!!userProfile && !ProfileBllService.isSymbolEditable(userProfile, cell.getValue()?.idSymbol));
        } else if (cellValue?.type === 'edge') {
            hasNonEditableSelectedEdge =
                hasNonEditableSelectedEdge ||
                (!!userProfile && !ProfileBllService.isEdgeEditable(userProfile, cell.getValue()?.edgeTypeId));

            hasNonDeletableSelectedEdge =
                hasNonDeletableSelectedEdge ||
                (!!userProfile && !ProfileBllService.isEdgeDeletable(userProfile, cell.getValue()?.edgeTypeId));
        } else if (cellValue?.type === 'shape') {
            hasSelectedShapesWithoutImage = hasSelectedShapesWithoutImage || !cell.getValue()?.imageId;
        }
    });

    return {
        firstSelectedCell,
        selectedCellsCount,
        selectedObjectsCount,
        selectedEdgesCount,
        hasNonEditableSelectedObject,
        hasNonEditableSelectedEdge,
        hasNonDeletableSelectedEdge,
        hasSelectedShapesWithoutImage,
    };
};
