import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import icSearch from '../../../../../../resources/icons/ic-search.svg';
import { NodeId } from '../../../../../../serverapi/api';
import { EditorToolbarSelectors } from '../../../../../../selectors/editorToolbar.selectors';
import { ToolbarBtn } from '../../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import { workspaceTabSetAction } from '../../../../../../actions/tabs.actions';
import { TabsSelectors } from '../../../../../../selectors/tabs.selectors';

export const SearchButton = () => {
    const dispatch = useDispatch();
    const disabled: boolean = useSelector(EditorToolbarSelectors.getSearchBtnDisabled);
    const tooltip: string = useSelector(EditorToolbarSelectors.getSearchBtnTooltip);
    const activeTabNodeId: NodeId | undefined = useSelector(TabsSelectors.getActiveTabId);

    return (
        <ToolbarBtn
            disabled={disabled}
            icon={icSearch}
            size="small"
            onClick={() =>
                dispatch(workspaceTabSetAction({ nodeId: activeTabNodeId, name: 'focusSearch', value: true }))
            }
            tooltip={tooltip}
        />
    );
};
