import React, { useRef } from 'react';
import { ScriptEditModeButton } from './Buttons/ScriptEditorMode.component';
import { ButtonGroup } from '../../../UIKit/components/Toolbar/ButtonGroup/ButtonGroup.component';
import { RunScriptButton } from './Buttons/RunScript.component';
import { observerUseEffect } from '../../../UIKit/components/Toolbar/Toolbars/hooks/observerUseEffect';
import { MainMenuWrapper } from '../../../UIKit/components/Toolbar/MainMenu/MainMenuWrapper.component';

export const ScriptGeneralToolbarComponent = () => {
    const mainMenuRef = useRef<HTMLTableCellElement | null>(null);
    const { menuWidth, countOfHiddenGroups } = observerUseEffect(mainMenuRef);

    const btns: JSX.Element[] = [
        <ButtonGroup isMainMenuBtn>
            <ScriptEditModeButton />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn>
            <RunScriptButton />
        </ButtonGroup>,
    ];

    return (
        <MainMenuWrapper
            mainMenuToolbar={btns}
            mainMenuRef={mainMenuRef}
            countOfHiddenGroups={countOfHiddenGroups}
            menuWidth={menuWidth}
            withFormatBtn={false}
        />
    );
};
