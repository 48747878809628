import type { Editor } from '@tiptap/react';
import React, { useContext } from 'react';
import icAddMacro from 'icons/toolbar/controls/ic-add-macro.svg';
import icCodeBlock from 'icons/toolbar/controls/ic-code-block.svg';
import icBlockquote from 'icons/toolbar/controls/ic-blockquote.svg';
import icToc from 'icons/toolbar/controls/ic-toc.svg';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { useSharedState } from '../UseSharedState.hook';
import { ControlsContext } from '../Controls.context';
import { CODE_BLOCK_NODE_NAME } from '../../Editor/extensions/constants';
import { ToolbarSelect } from '../../../Toolbar/ButtonGroup/ToolbarSelect';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';
import { TToolbarBtn } from '../../../Toolbar/ButtonGroup/toolbarBtns.types';

const getFocusedBlock = (editor: Editor) => ({
    isCodeBlock: editor.isActive(CODE_BLOCK_NODE_NAME),
    isBlockquote: editor.isActive('blockquote'),
});

const getChangedBlock = (editor: Editor, action: string) => {
    switch (action) {
        case 'CODE_BLOCK':
            return editor.chain().focus().insertContent({ type: CODE_BLOCK_NODE_NAME }).run();
        case 'BLOCKQUOTE':
            return editor.chain().focus().toggleBlockquote().run();
        case 'TOC':
            return editor.commands.insertTableOfContents();
        default:
            return false;
    }
};

export const MacrosComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState, value } = useSharedState<{ isCodeBlock: boolean; isBlockquote: boolean }, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    const onChange = (value: string) => {
        setCurrentState(value);
    };

    const menuItems = {
        codeBlock: {
            action: 'CODE_BLOCK',
            tooltip: messages.codeBlock,
            icon: icCodeBlock,
            // dataTest: '',
        },
        blockquote: {
            action: 'BLOCKQUOTE',
            tooltip: messages.blockquote,
            icon: icBlockquote,
            // dataTest: '',
        },
        tableOfContents: {
            action: 'TOC',
            tooltip: messages.tableOfContents,
            icon: icToc,
            // dataTest: '',
        },
    };

    const macrosButtons: React.ReactElement<TToolbarBtn, typeof ToolbarBtn>[] = Object.getOwnPropertyNames(
        menuItems,
    ).map((key) => {
        return (
            <ToolbarBtn
                icon={menuItems[key].icon}
                tooltip={intl.formatMessage(menuItems[key].tooltip)}
                onClick={() => onChange(menuItems[key].action)}
                isActive={
                    (!!value?.isCodeBlock && key === 'codeBlock') || (!!value?.isBlockquote && key === 'blockquote')
                }
            />
        );
    });

    return (
        <ToolbarSelect
            icon={icAddMacro}
            tooltip={intl.formatMessage(messages.macros)}
            options={macrosButtons}
            size="small"
        />
    );
};
