import type {
    TCommentTooltipProps,
    TRichTextEditorProps,
} from '@/modules/UIKit/components/TipTapTextEditor/Editor/RichTextEditor.types';
import type { TRichTextEditor } from '@/modules/UIKit/components/RichTextEditor/Editor/RichTextEditor.types';
import type { TDefaultCommentSize } from '@/modules/Comments/types/defaultCommentSize.types';
import type { NodeId } from '@/serverapi/api';
import type { Editor } from '@tiptap/react';
import React, { FC, useEffect, useMemo } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import theme from './WikiEditor.component.scss';
import SplitPane from 'react-split-pane';
import DraftJsTextEditor from '../../UIKit/components/RichTextEditor/Editor/RichTextEditor.component';
import { RichTextEditor as TipTapTextEditor } from '../../UIKit/components/TipTapTextEditor/Editor/RichTextEditor.component';
import { CommentsPanelContainer } from '@/modules/Comments/containers/CommentsPanel.container';
import { CommentPopoverContainer } from '@/modules/Comments/containers/CommentPopover.container';
import { DetailedCommentTooltip } from '@/modules/Comments/components/DetailedCommentTooltip/DetailedCommentTooltip.component';
import { useDefaultCommentsSize } from '../../Comments/hooks/useDefaultCommentsSize';
import { TWikiEditorProps } from './WikiEditor.types';
import { Provider } from 'react-redux';
import { getStore } from '@/store';
import Portal from '@/modules/UIKit/components/Portal/Portal.component';
import { Header } from './Header.component';
import { WikiEditorToolbarContainer } from '../containers/WikiEditorToolbar.container';

const getCommentTooltip = (modelId: NodeId) =>
    React.forwardRef(
        (props: TCommentTooltipProps, commentTooltipRef: React.ForwardedRef<typeof DetailedCommentTooltip>) => (
            <Provider store={getStore()}>
                <Portal container={props.container}>
                    <DetailedCommentTooltip
                        ref={commentTooltipRef}
                        modelId={modelId}
                        commentId={props.commentId}
                        maxTooltipHeight={props.maxTooltipHeight}
                        style={props.container.style}
                        container={props.container}
                        onClose={props.onClose}
                        resize={props.onResize}
                        setFullTooltipMode={props.setFullTooltipMode}
                    />
                </Portal>
            </Provider>
        ),
    );

const WikiEditor: FC<TWikiEditorProps> = ({
    id,
    value,
    zoomLevel = 100,
    onChange,
    onRendered,
    onUnmount,
    handlers,
    isShowCommentsPanel,
    modelId,
    isNewEditor,
    title,
    createdBy,
    updatedBy,
    updatedAt,
}) => {
    const { defaultCommentSize, changeCommentsTabSizeHandler }: TDefaultCommentSize = useDefaultCommentsSize();

    const handleRendered = (editor: Editor) => {
        if (onRendered) {
            onRendered(editor);
        }
    };

    useEffect(() => {
        return () => {
            if (onUnmount) {
                onUnmount(modelId);
            }
        };
    }, []);

    const Editor: React.FC<TRichTextEditorProps | TRichTextEditor> = useMemo(
        () => (isNewEditor ? TipTapTextEditor : DraftJsTextEditor),
        [value],
    );

    const CommentPopoverContent = useMemo(
        () => (isNewEditor ? getCommentTooltip(modelId) : CommentPopoverContainer),
        [modelId],
    );

    return (
        <div className={theme.container}>
            <div className={theme.mainMenuContainer}>
                {/* {isNewEditor ? (
                    <ControlsContextNewMatrix.Provider value={getStateObserverNewMtrix(id)}>
                        <WikiToolbar />
                    </ControlsContextNewMatrix.Provider>
                ) : (
                    <ControlsContextOldMtarix.Provider value={getStateObserverOldMatrix(id)}>
                        <MainMenu />
                    </ControlsContextOldMtarix.Provider>
                )} */}
                <WikiEditorToolbarContainer nodeId={modelId} />
            </div>
            <div className={theme.editorContainer} data-test="wiki-editor-outer_container">
                {/* @ts-ignore */}
                <SplitPane
                    split="vertical"
                    primary="second"
                    defaultSize={isShowCommentsPanel ? defaultCommentSize : 0}
                    maxSize={isShowCommentsPanel ? 750 : 0}
                    minSize={isShowCommentsPanel ? 300 : 0}
                    style={{ position: 'relative' }}
                    paneStyle={{ overflow: 'auto' }}
                    resizerClassName={isShowCommentsPanel ? theme.resizer : theme.hideResizer}
                    pane2Style={{ borderLeft: '1px solid #d9d9d9' }}
                    onChange={changeCommentsTabSizeHandler}
                >
                    <div style={{ transform: `scale(${zoomLevel / 100})` }} className={theme.scaleContainer}>
                        <Header title={title} createdBy={createdBy} updatedBy={updatedBy} updatedAt={updatedAt} />
                        {/* @ts-ignore */}
                        <Editor
                            id={id}
                            value={value}
                            onChange={onChange}
                            onRendered={handleRendered}
                            handlers={handlers}
                            CommentPopoverContent={CommentPopoverContent}
                            modelId={modelId}
                        />
                    </div>
                    <CommentsPanelContainer modelNodeId={modelId} />
                </SplitPane>
            </div>
        </div>
    );
};

export default WikiEditor;
