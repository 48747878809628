import { useDispatch, useSelector } from 'react-redux';
import { MatrixEditorSelectors } from '../../../../Matrix/selectors/matrixEditor.selectors';
import { TabsSelectors } from '../../../../../selectors/tabs.selectors';
import { NodeId } from '../../../../../serverapi/api';
import { ToolbarBtn } from '../../../../UIKit/components/Toolbar/ButtonGroup/ToolbarBtn.component';
import React from 'react';
import { matrixPastEmptyLane } from '../../../../Matrix/actions/matrixEditor.actions';
import icPasteRowBtm from '@/resources/icons/toolbar/pastRowBtm.svg';

export const PasteEmptyLaneBottom = () => {
    const dispatch = useDispatch();
    const activeTabNodeId: NodeId = useSelector(TabsSelectors.getActiveTabId);
    const disabled: boolean = useSelector(MatrixEditorSelectors.getRowPasteDisabled(activeTabNodeId));
    const tooltip: string = useSelector(MatrixEditorSelectors.getRowPasteBottomTooltip(activeTabNodeId));

    const pastEmptyLane = (pastOnTheOtherSide?: boolean) => {
        dispatch(matrixPastEmptyLane(activeTabNodeId, pastOnTheOtherSide));
    };

    return (
        <ToolbarBtn
            icon={icPasteRowBtm}
            size="small"
            disabled={disabled}
            onClick={() => pastEmptyLane(true)}
            tooltip={tooltip}
        />
    );
};
