import React, { useContext } from 'react';
import icBold from 'icons/toolbar/controls/ic-font-bold.svg';
import ControlsContext from '../../../RichTextEditor/Toolbar/Controls.context';
import { useSharedState } from '../../../RichTextEditor/Toolbar/UseSharedState.hook';
import { EditorState, RichUtils } from 'draft-js';
import { getSelectionInlineStyle } from 'draftjs-utils';
import { useIntl } from 'react-intl';
import { StateObserver } from '../../../RichTextEditor/common/sharedState.class';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';
import messages from '../CommonToolbar.messages';

type TInlineStyleValue = {
    [key: string]: boolean;
};

const getFocusedBlock = (editorState: EditorState) => getSelectionInlineStyle(editorState);
const getChangedBlock = (editorState: EditorState, style: string) => {
    return RichUtils.toggleInlineStyle(editorState, style);
};

export const BoldTextComponent = () => {
    const intl = useIntl();
    const stateObserver: StateObserver | undefined = useContext(ControlsContext);

    const { setCurrentState, value } = useSharedState<TInlineStyleValue, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });

    return (
        <ToolbarBtn
            icon={icBold}
            dataTest="wiki-toolbar-group_font-inline-style_BOLD"
            tooltip={intl.formatMessage(messages.textBold)}
            onClick={() => setCurrentState('BOLD')}
            isActive={!!value?.BOLD}
            size="small"
        />
    );
};
