import React from 'react';
import theme from './ButtonGroup.scss';
import classnames from 'classnames';
import { TButtonGroup } from './ButtonGroup.types';

export const ButtonGroup = (props: TButtonGroup) => {
    const { isMainMenuBtn, withDividerSpace, children } = props;
    const buttonGroupClass: string = classnames(theme.container, {
        [theme.mainMenuBtn]: isMainMenuBtn,
    });

    return (
        <div className={buttonGroupClass}>
            {Array.isArray(children) ? (
                children.map((child) => <div className={withDividerSpace ? theme.btnItem : ''}>{child}</div>)
            ) : (
                <div className={withDividerSpace ? theme.btnItem : ''}>{children}</div>
            )}
        </div>
    );
};
