import type { Editor } from '@tiptap/react';
import React, { useContext, useState } from 'react';
import messages from '../CommonToolbar.messages';
import { useIntl } from 'react-intl';
import { DEFAULT_FONT_COLOR } from './Controls.constants';
import { ControlsContext } from '../Controls.context';
import { useSharedState } from '../UseSharedState.hook';
import { ColorResult, SketchPicker } from 'react-color';
import { ToolbarBtn } from '../../../Toolbar/ButtonGroup/ToolbarBtn.component';
import icTextColor from '@/resources/icons/MainMenuText-Color.svg';

const getFocusedBlock = (editor: Editor) => editor.getAttributes('textStyle').color || DEFAULT_FONT_COLOR;

const getChangedBlock = (editor: Editor, value: string) => editor.chain().focus().setColor(value).run();

export const FontColorComponent = () => {
    const stateObserver = useContext(ControlsContext);
    const intl = useIntl();
    const { setCurrentState } = useSharedState<string, string>({
        stateObserver,
        getFocusedBlock,
        getChangedBlock,
    });
    const selectFontColorHandler = (colorObject: ColorResult) => {
        const { hex } = colorObject;
        setCurrentFontColor(hex);
        setCurrentState(hex);
    };
    const [currentFontColor, setCurrentFontColor] = useState<string>(DEFAULT_FONT_COLOR);

    return (
        <ToolbarBtn
            icon={icTextColor}
            dataTest="wiki-toolbar-group_font-color-button"
            overlay={<SketchPicker color={currentFontColor} onChange={selectFontColorHandler} disableAlpha />}
            tooltip={intl.formatMessage(messages.fontColor)}
            size="small"
        />
    );
};
