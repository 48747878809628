import { useEffect, useState } from 'react';

export const observerUseEffect = (mainMenuRef: React.MutableRefObject<HTMLTableCellElement | null>) => {
    const [menuWidth, setMenuWidth] = useState<number>(0);
    const [countOfHiddenGroups, setCountOfHiddenGroups] = useState<number>(0);
    const changeAmountOfGroups = (entries: ResizeObserverEntry[]) => {
        const [mainButtons] = entries[0].target.children;
        const mainButtonsWidth = mainButtons.getBoundingClientRect().width;
        const childrenWidths: number[] = Array.from(mainButtons.children).map(
            (child) => child.getBoundingClientRect().width,
        );
        let childrenWidth = childrenWidths.reduce(function (a, b) {
            return a + b;
        }, 0);
        let childrenToHide: number = 0;
        if (Math.round(childrenWidth) > Math.round(mainButtonsWidth)) {
            while (childrenWidth > mainButtonsWidth) {
                childrenToHide = childrenToHide + 1;
                childrenWidth = childrenWidths
                    .filter((el, ind) => ind < childrenWidths.length - childrenToHide * 2)
                    .reduce(function (a, b) {
                        return a + b;
                    }, 0);
            }

            setCountOfHiddenGroups(childrenToHide);
        } else {
            setCountOfHiddenGroups(0);
        }
        setMenuWidth(mainMenuRef.current?.getBoundingClientRect().width || 0);
    };
    useEffect(() => {
        const observer = new ResizeObserver((entries) => {
            changeAmountOfGroups(entries);
        });
        if (mainMenuRef?.current) {
            observer.observe(mainMenuRef.current);
        }

        return () => {
            mainMenuRef?.current && observer.unobserve(mainMenuRef?.current);
        };
    }, [mainMenuRef?.current]);

    return { menuWidth, countOfHiddenGroups };
};
