// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MainMenu__mainMenu___TS9v{display:-webkit-box;display:-ms-flexbox;display:flex;height:40px;-webkit-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:justify;-ms-flex-pack:justify;justify-content:space-between;border-bottom:1px solid var(--SecondaryNeutral200);padding:6px 8px}.MainMenu__mainButtons__fn5Up{display:-webkit-box;display:-ms-flexbox;display:flex;overflow:hidden}.MainMenu__formatButton___eXlT{-webkit-box-flex:22;-ms-flex-positive:22;flex-grow:22;display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-pack:end;-ms-flex-pack:end;justify-content:flex-end}.MainMenu__hiiddenGroup__elbCT{opacity:0}", "",{"version":3,"sources":["webpack://./src/modules/UIKit/components/Toolbar/MainMenu/MainMenu.scss"],"names":[],"mappings":"AAAA,2BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,WAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,kBAAA,CACA,wBAAA,CAAA,qBAAA,CAAA,6BAAA,CACA,kDAAA,CACA,eAAA,CAGJ,8BACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,eAAA,CAGJ,+BACI,mBAAA,CAAA,oBAAA,CAAA,YAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,oBAAA,CAAA,iBAAA,CAAA,wBAAA,CAIJ,+BACI,SAAA","sourcesContent":[".mainMenu {\r\n    display: flex;\r\n    height: 40px;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    border-bottom: 1px solid var(--SecondaryNeutral200);\r\n    padding: 6px 8px;\r\n}\r\n\r\n.mainButtons {\r\n    display: flex;\r\n    overflow: hidden;\r\n}\r\n\r\n.formatButton {\r\n    flex-grow: 22;\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    //flex-direction: row-reverse;\r\n}\r\n\r\n.hiiddenGroup {\r\n    opacity: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainMenu": "MainMenu__mainMenu___TS9v",
	"mainButtons": "MainMenu__mainButtons__fn5Up",
	"formatButton": "MainMenu__formatButton___eXlT",
	"hiiddenGroup": "MainMenu__hiiddenGroup__elbCT"
};
export default ___CSS_LOADER_EXPORT___;
