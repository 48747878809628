import React, { useRef } from 'react';
import TextAlignComponent from './controls/TextAlign.component';
import FontFamilyComponent from './controls/FontFamily.component';
import FontSizeComponent from './controls/FontSize.component';
import FontColorComponent from './controls/FontColor.component';
import BGColorComponent from './controls/BGColor.component';
import ImageComponent from './controls/Image.component';
import TableComponent from './controls/Table.component';
import LinkComponent from './controls/Link.component';
import EraserComponent from './controls/Eraser.component';
import LineHeightComponent from './controls/LineHeight.component';
import IndentComponent from './controls/Indent.component';
import getStateObserver from '../common/sharedState.class';
import ControlsContext from './Controls.context';
import { observerUseEffect } from '../../Toolbar/Toolbars/hooks/observerUseEffect';
import { MainMenuWrapper } from '../../Toolbar/MainMenu/MainMenuWrapper.component';
import { ButtonGroup } from '../../Toolbar/ButtonGroup/ButtonGroup.component';
import { ItalicTextComponent } from './controls/ItalicTextComponent.component';
import { BulletedListComponent } from './controls/BulletedListComponent.component';
import { OrderedListComponent } from './controls/OrderedListComponent.component';
import { CheckableListComponent } from './controls/CheckableListComponent.component';
import { IncreaseListComponent } from './controls/IncreaseListComponent.component';
import { DescreaseListIndent } from './controls/DescreaseListIndent.component';
import { BoldTextComponent } from './controls/BoldTextComponent.component';
import { UnderlineTextComponent } from './controls/UnderlineTextComponent.component';
import { InlineStylesComponent } from './controls/InlineStylesComponent.component';

const RichTextEditorToolbar = ({ id, handlers }) => {
    const stateObserverStateObserver = getStateObserver(id);
    const { openImageDialog, openLinkDialog, uploadFile, hideIndent } = handlers;
    const mainMenuRef = useRef<HTMLTableCellElement | null>(null);
    const { menuWidth, countOfHiddenGroups } = observerUseEffect(mainMenuRef);

    const btns: JSX.Element[] = [
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <FontFamilyComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <FontSizeComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <LineHeightComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <FontColorComponent />
            <BGColorComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <BoldTextComponent />
            <ItalicTextComponent />
            <UnderlineTextComponent />
            <InlineStylesComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <TextAlignComponent />
            <IndentComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <BulletedListComponent />
            <OrderedListComponent />
            <CheckableListComponent />
            {!hideIndent ? <IncreaseListComponent /> : null}
            {!hideIndent ? <DescreaseListIndent /> : null}
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <LinkComponent openDialog={openLinkDialog} />
            <ImageComponent openDialog={openImageDialog} uploadFile={uploadFile} />
            <TableComponent />
        </ButtonGroup>,
        <ButtonGroup isMainMenuBtn withDividerSpace>
            <EraserComponent />
        </ButtonGroup>,
    ];

    return (
        <ControlsContext.Provider value={stateObserverStateObserver}>
            <MainMenuWrapper
                mainMenuToolbar={btns}
                mainMenuRef={mainMenuRef}
                countOfHiddenGroups={countOfHiddenGroups}
                menuWidth={menuWidth}
                withFormatBtn={false}
            />
        </ControlsContext.Provider>
    );
};

export default RichTextEditorToolbar;
