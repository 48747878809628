import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { closeDialog } from '../../../../../../actions/dialogs.actions';
import { DialogType } from '../../../../../DialogRoot/DialogRoot.constants';
import { DialogFooterButtons } from '../../../DialogFooterButtoms/DialogFooterButtons.component';
import messages from './AddNewValueDialog.messages';
import { Dialog } from '../../../Dialog/Dialog.component';
import { getStore } from '../../../../../../store';
import { Form } from 'antd';
import { Input } from '../../../Input/Input.component';
import { FormInstance, Rule } from 'antd/es/form';

type TAddNewValueDialog = {
    handleOk: (newValue: string) => void;
    title: string;
    validationRules: Rule[] | undefined;
};

export const AddNewValueDialog = (props: TAddNewValueDialog) => {
    const intl = useIntl();
    const { handleOk, title, validationRules } = props;
    const handleCancel = () => {
        getStore().dispatch(closeDialog(DialogType.ADD_NEW_VALUE_DIALOG));
    };
    const formRef = useRef<FormInstance>(null);
    const handlecontinueSave = () => {
        const form = formRef.current;
        if (form) {
            form.validateFields()
                .then((formValues: { addValue: string }) => {
                    handleOk(formValues.addValue);
                    getStore().dispatch(closeDialog(DialogType.ADD_NEW_VALUE_DIALOG));
                })
                .catch((error) => console.error(error));
        }
    };
    const footer = (
        <DialogFooterButtons
            buttons={[
                {
                    key: 'cancel',
                    onClick: handleCancel,
                    value: intl.formatMessage(messages.cancel),
                },
                {
                    key: 'ok',
                    onClick: handlecontinueSave,
                    value: intl.formatMessage(messages.continueSave),
                    visualStyle: 'primary',
                },
            ]}
        />
    );

    return (
        <Dialog onCancel={handleCancel} open footer={footer}>
            <Form ref={formRef}>
                <Form.Item labelCol={{ span: 24 }} label={title} name="addValue" rules={validationRules}>
                    <Input name={'addValueInput'} />
                </Form.Item>
            </Form>
        </Dialog>
    );
};
